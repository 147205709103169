import { axiosInstance } from './axios';

export interface DeleteVideoLinkData {
  id: number;
}

const deleteVideoLink = async (data: DeleteVideoLinkData) =>
  axiosInstance.delete(`/users/video-links/${data.id}`);

export default deleteVideoLink;
