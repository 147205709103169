import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  ComingSoonDrone1,
  ComingSoonDrone2,
  Drone2,
} from '../../resources/icons';

export const NotFound: React.FC = () => (
  <div className="not-found-container position-relative h-100 w-100 min-vh-100 min-vw-100 d-flex flex-column justify-content-center">
    <div className="not-found-background-img">
      <div className="not-found-container-background-color">
        <ComingSoonDrone1 className="coming-soon-drone-1 position-absolute z-1" />
        <ComingSoonDrone2 className="coming-soon-drone-2 position-absolute z-1" />
        <div className="not-found-center-block m-auto d-flex flex-column justify-content-between">
          <div className="coming-soon-center-block-header d-flex flex-column mx-auto">
            <Link to="/">
              <div className="coming-soon-center-block-logo d-flex justify-content-center">
                <Drone2
                  style={{
                    width: '140px',
                    height: '140px',
                  }}
                />
                <div className="d-flex flex-column justify-content-center">
                  <span>CaptureHub</span>
                </div>
              </div>
            </Link>
            <div className="not-found-center-block mx-auto text-center">
              <div className="not-found-title">404</div>
              <div className="not-found-description">Page not found</div>
              <div className="not-found-sub-description">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Uh-oh! You've reached a dead end. Don't worry, it happens to the
                best of us. How about heading back to our homepage to continue
                your journey? Or you can contact us if you need assistance.
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                We're here to help!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
