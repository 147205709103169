import React, { useState } from 'react';

interface SmallToggleButtonProps {
  onToggle: (toggle: boolean) => void;
  initialToggle?: boolean;
}

export const SmallToggleButton: React.FC<SmallToggleButtonProps> = ({
  onToggle,
  initialToggle,
}) => {
  const [toggle, setToggle] = useState(initialToggle || false);

  return (
    <div
      className={`small_toggle_button_container ${toggle ? 'small_toggle_button_container_active' : ''}`}
      onClick={() => {
        setToggle(!toggle);
        onToggle(!toggle);
      }}
    >
      <div
        className={`small_toggle_button_circle ${toggle ? 'small_toggle_button_circle_active' : ''}`}
      />
    </div>
  );
};
