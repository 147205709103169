import * as React from 'react';

export const ForgotPasswordSuccessResetForm: React.FC = () => (
  <div className="forgot_password_content">
    <div className="forgot_password_header">Reset password</div>
    <div className="forgot_password_success_form_body">
      <div className="forgot_password_success_form_body_content">
        Password successfully changed
      </div>
    </div>
  </div>
);
