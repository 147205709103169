import * as React from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthInput } from '../../AuthInput';
import { Password } from '../../../../resources/icons';
import { MainButton } from '../../MainButton';
import resetPassword from '../../../../api/resetPassword';
import { fieldSetErrors, getApiError } from '../../../../api/handleError';

export interface ForgotPasswordResetFormProps {
  onSubmit: () => void;
}

export const ForgotPasswordResetForm: React.FC<
  ForgotPasswordResetFormProps
> = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [searchParams] = useSearchParams();

  const onClickMainButton = () => {
    const token = searchParams.get('token');

    if (!token) {
      setPasswordError(' ');
      setRepeatPasswordError(
        'This link in not valid anymore, please request a new one.',
      );
      return;
    }

    if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords are not same!');
      return;
    }

    setPasswordError('');
    setRepeatPasswordError('');

    resetPassword({
      token,
      password,
    })
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        const hasFieldErrors = fieldSetErrors(error, {
          password: setPasswordError,
        });
        if (!hasFieldErrors) {
          setPasswordError(' ');
          setRepeatPasswordError(getApiError(error));
        }
      });
  };

  return (
    <div className="forgot_password_content">
      <div className="forgot_password_header m-0">Reset password</div>
      <div className="auth_inputs">
        <AuthInput
          iconComponent={Password}
          onChange={setPassword}
          error={passwordError}
          placeholder="Password"
          textType="password"
        />
        <AuthInput
          iconComponent={Password}
          onChange={setRepeatPassword}
          error={repeatPasswordError}
          placeholder="Repeat password"
          textType="password"
        />
      </div>
      <div className="forgot_password_button">
        <MainButton
          text="Submit"
          buttonColor={
            password && repeatPassword ? '#C3FF01' : 'rgba(195, 255, 1, 0.50)'
          }
          cursor={password && repeatPassword ? 'pointer' : 'not-allowed'}
          onClick={password && repeatPassword ? onClickMainButton : () => null}
        />
      </div>
    </div>
  );
};
