import { Navigate, Outlet } from 'react-router-dom';
import * as React from 'react';
import { useAuth } from './authContext';

export const ProtectedRoute = () => {
  const auth = useAuth();
  const token = auth?.token;

  if (!token) {
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export const AuthenticatedRoute = () => {
  const auth = useAuth();
  const token = auth?.token;

  if (token) {
    return <Navigate to="/app" />;
  }

  return <Outlet />;
};
