import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthenticatedRoute, ProtectedRoute } from '../contexts/protectedRoute';
import { HomePage } from '../components';
import { AuthPage } from '../components/AuthPage';
import { ComingSoon } from '../components/ComingSoon';
import { NotFound } from '../components/Error';
import { MainPage } from '../components/MainPage';

export const AppRoutes = () => {
  const routesForPublic = [
    {
      path: '/',
      element: <HomePage />,
    },
    // {
    //   path: 'coming-soon',
    //   element: <ComingSoon />,
    // },
    // {
    //   path: 'auth',
    //   element: <AuthPage />,
    // },
    {
      path: 'auth/activate-account',
      element: <AuthPage initialForm="activateAccount" />,
    },
    {
      path: 'auth/reset-password',
      element: <AuthPage initialForm="resetPassword" />,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute />,
      children: [
        {
          path: 'app',
          element: <MainPage />,
        },
      ],
    },
  ];

  const routesForNotAuthenticated = [
    {
      path: '/',
      element: <AuthenticatedRoute />,
      children: [
        {
          path: 'auth',
          element: <AuthPage />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticated,
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} />;
};
