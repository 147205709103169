import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { AxiosError } from 'axios';
import Select from 'react-select';
import { SmallToggleButton } from '../../../SmallToggleButton';
import { MainButton } from '../../MainButton';
import getDrones from '../../../../api/getDrones';
import { useAuth } from '../../../../contexts/authContext';
import { getApiError } from '../../../../api/handleError';
import getIndustries from '../../../../api/getIndustries';
import { AuthInput } from '../../AuthInput';
import { CrossIcon, LinkIcon } from '../../../../resources/icons';
import postRegister from '../../../../api/register';
import postLogin from '../../../../api/login';
import getMe from '../../../../api/me';
import { SignupFormData } from '../Signup';

export const DetailsForm = ({
  data,
  onSubmit,
}: {
  data: SignupFormData;
  onSubmit: () => void;
}) => {
  const [isCertified, setIsCertified] = useState<boolean>(false);
  const [isInsured, setIsInsured] = useState<boolean>(false);

  const [selectedDroneIds, setSelectedDroneIds] = useState<any[]>([]);
  const [selectedIndustriesIds, setSelectedIndustriesIds] = useState<any[]>([]);

  const [insertedLink, setInsertedLink] = useState('');
  const [insertedLinks, setInsertedLinks] = useState<string[]>([]);
  const [insertedLinkError, setInsertedLinkError] = useState('');

  const [formError, setFormError] = useState('');

  const [dronesList, setDronesList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);

  const [otherOwnedDrones, setOtherOwnedDrones] = useState('');
  const [otherIndustries, setOtherIndustries] = useState('');

  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const setToken = auth?.setToken;
  const setUserId = auth?.setUserId;
  const setUserName = auth?.setUserName;

  useEffect(() => {
    getDrones('').then((response) => {
      setDronesList(
        response.data.results
          .map((drone) => ({
            label: drone.name,
            value: drone.id,
          }))
          .concat({
            label: 'Other',
            value: -1,
          }),
      );
    });
    getIndustries('').then((response) =>
      setIndustriesList(
        response.data.results
          .map((industry) => ({
            label: industry.name,
            value: industry.id,
          }))
          .concat({
            label: 'Other',
            value: -1,
          }),
      ),
    );
  }, []);

  const onClickMainButton = async () => {
    setLoading(true);

    try {
      await postRegister({
        email: data.email,
        role: data.role,
        first_name: data.first_name,
        last_name: data.last_name,
        business_name: data.business_name ? data.business_name : null,
        password: data.password,
        is_certified: isCertified,
        is_insured: isInsured,
        owned_drones: selectedDroneIds.filter((id) => id !== -1),
        industries: selectedIndustriesIds.filter((id) => id !== -1),
        other_owned_drones: selectedDroneIds.includes(-1)
          ? otherOwnedDrones
          : '',
        other_industries: selectedIndustriesIds.includes(-1)
          ? otherIndustries
          : '',
        services: [],
        video_links: insertedLinks,
      });

      try {
        const response = await postLogin({
          email: data.email,
          password: data.password,
        });

        if (setToken) {
          await setToken(response.data.access);
        }
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);

        try {
          const meResponse = await getMe();
          if (setUserId) {
            await setUserId(meResponse.data.id);
          }
          if (setUserName) {
            await setUserName(
              `${meResponse.data.first_name} ${meResponse.data.last_name}`,
            );
          }
        } catch (meError) {
          setFormError(getApiError(meError));
        }
      } catch (loginError) {
        if (loginError instanceof AxiosError) {
          setFormError(getApiError(loginError));
        }
      }
      onSubmit();
    } catch (registerError) {
      setFormError(getApiError(registerError));
    } finally {
      setLoading(false);
    }
  };

  function isVideoLinkValid(value: string): boolean {
    const allowedLinks: string[] = [
      'https://www.youtube.com/',
      'https://youtube.com/',
      'https://www.vimeo.com/',
      'https://vimeo.com/',
    ];

    return allowedLinks.some((allowedLink) => value.startsWith(allowedLink));
  }

  return (
    <div className="auth_form_container">
      <div className="auth_form_content">
        <div className="auth_form_small_header">
          <span>Details</span>
          <div className="auth_form_header_description">
            Fill up the details so that our algorithms could better find jobs
            that better fit your needs. Additionally, you can skip this steps
            and fill them later in Settings.
          </div>
          <div className="invalid-feedback d-block">{formError}</div>
          <div className="auth_form_body_toggle_fields">
            <div className="auth_form_body_toggle_field">
              <div className="justify-content-between">
                Are you certified drone operator?
                <SmallToggleButton onToggle={setIsCertified} />
              </div>
            </div>
            <div className="auth_form_body_toggle_field">
              <div className="justify-content-between">
                Are you insured?
                <SmallToggleButton onToggle={setIsInsured} />
              </div>
            </div>
          </div>
          <div className="auth_form_body_search_fields">
            <div className="auth_form_body_search_field">
              <span className="mb-8">Drones you own</span>
              <Select
                isMulti
                name="colors"
                options={dronesList}
                className="basic-multi-select mb-8"
                classNamePrefix="select"
                onChange={(selectedOptions) => {
                  setSelectedDroneIds(
                    selectedOptions.map((option: any) => option.value),
                  );
                }}
                placeholder="Search and select"
              />
              {selectedDroneIds.includes(-1) ? (
                <AuthInput
                  onChange={setOtherOwnedDrones}
                  placeholder="Insert here other drones you own"
                />
              ) : (
                ''
              )}
            </div>
            <div className="auth_form_body_search_field">
              <span className="mb-8">Industry expertise</span>
              <Select
                isMulti
                name="colors"
                options={industriesList}
                className="basic-multi-select mb-8"
                classNamePrefix="select"
                onChange={(selectedOptions) => {
                  setSelectedIndustriesIds(
                    selectedOptions.map((option: any) => option.value),
                  );
                }}
                placeholder="Search and select"
              />
              {selectedIndustriesIds.includes(-1) ? (
                <AuthInput
                  onChange={setOtherIndustries}
                  placeholder="Insert here your other industry expertise"
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="portfolio_upload_videos">
            <div className="portfolio_upload_videos_header mb-8">
              <span>Videos</span>
            </div>
            <AuthInput
              placeholder="Insert link"
              currentValue={insertedLink}
              onChange={setInsertedLink}
              error={insertedLinkError}
              rightElement={
                <div
                  onClick={() => {
                    setInsertedLinkError('');
                    if (!isVideoLinkValid(insertedLink)) {
                      setInsertedLinkError(
                        'Link must be a valid YouTube or Vimeo video link',
                      );
                      return;
                    }
                    setInsertedLinks([...insertedLinks, insertedLink]);
                    setInsertedLink('');
                  }}
                  className="portfolio-upload-videos-embed-button my-auto"
                >
                  <div className="portfolio-upload-videos-embed-button-content h-100 d-flex">
                    <div className="portfolio-upload-videos-embed-button-icon d-flex flex-column justify-content-center h-100">
                      <LinkIcon />
                    </div>
                    <div className="portfolio-upload-videos-embed-button-text d-flex flex-column justify-content-center">
                      <span>Embed</span>
                    </div>
                  </div>
                </div>
              }
            />
            {insertedLinks.length > 0 ? (
              <div className="mt-8">
                {insertedLinks.map((item) => (
                  <div className="d-flex justify-content-center">
                    <div className="position-relative d-inline-flex mb-8">
                      <div
                        className="delete_video_icon"
                        onClick={() => {
                          setInsertedLinks(
                            insertedLinks.filter((link) => link !== item),
                          );
                        }}
                      >
                        <CrossIcon />
                      </div>
                      <ReactPlayer url={item} width="100%" height="" controls />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <div className="details-form-main-button">
            <MainButton
              text="Submit"
              buttonColor="#C3FF01"
              onClick={onClickMainButton}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
