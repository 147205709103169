import { axiosInstance } from './axios';

export interface resetPasswordData {
  old_password: string;
  new_password: string;
}

const postChangePassword = async (data: resetPasswordData) =>
  axiosInstance.post('/users/users/change-password', data);

export default postChangePassword;
