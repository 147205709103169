import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { AppRoutes } from './routes';
import AuthProvider from '../contexts/authContext';

mapboxgl.accessToken = 'YOUR_MAPBOX_ACCESS_TOKEN';

export const App: React.FC = () => (
  <AuthProvider>
    <ToastContainer />
    <AppRoutes />
  </AuthProvider>
);
