// eslint-disable-next-line import/no-extraneous-dependencies
import Map, { GeolocateControl, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ToggleButton } from '../ToggleButton';
import {
  Arrow,
  ClientIcon,
  CrossIcon,
  DashboardIcon,
  FindWorkIcon,
  JoystickIcon,
  LinkIcon,
  NotificationIcon,
  PhoneIcon,
  ResetIcon,
  TuneIcon,
} from '../../resources/icons';
import { AuthSelectField } from '../AuthPage/AuthSelectField';
import { CustomButton } from '../CustomButton';
import { Notification } from './Header/Notification';
import { WorkHeader } from './WorkHeader';
import { Invites } from './Pilot/Invites';
import { Proposals } from './Pilot/Proposals';
import { Jobs } from './Pilot/Jobs';
import { Contracts } from './Pilot/Contracts';
import { useAuth } from '../../contexts/authContext';
import useWindowDimensions from '../../utils/windowDimensions';
import postLogout from '../../api/postLogout';
import { SmallToggleButton } from '../SmallToggleButton';
import { AuthInput } from '../AuthPage/AuthInput';
import getDrones from '../../api/getDrones';
import getIndustries from '../../api/getIndustries';
import patchUser from '../../api/patchUser';
import deleteVideoLink from '../../api/deleteVideoLink';
import postVideoLink from '../../api/postVideoLink';
import { MainButton } from '../AuthPage/MainButton';
import requestResetPassword from '../../api/requestResetPassword';
import { fieldSetErrors, getApiError } from '../../api/handleError';
import postChangePassword from '../../api/postChangePassword';
import { Loader } from '../Loader';

export const MainPage = () => {
  const mapboxToken =
    'pk.eyJ1IjoieGlhb2l2ZXIiLCJhIjoiY2pxcmc5OGNkMDY3cjQzbG42cXk5NTl3YiJ9.hUC5Chlqzzh0FFd_aEc-uQ';

  const auth = useAuth();
  const navigate = useNavigate();
  const authUserRole = auth?.userRole;
  const authUserIndustries = auth?.userIndustries;
  const authUserDrones = auth?.ownedDrones;
  const authUserOtherIndustries = auth?.otherIndustries;
  const authUserOtherDrones = auth?.otherDrones;
  const authUserIsCertified = auth?.isCertified;
  const authUserHasInsurance = auth?.hasInsurance;
  const authUserPhoneNumber = auth?.phoneNumber;
  const authUserPortfolioVideos = auth?.portfolioVideos;

  const { windowWidth } = useWindowDimensions();

  const onMove = React.useCallback((evt) => setViewState(evt.viewState), []);

  const [mapControlsMoved, setMapControlsMoved] = useState(false);

  const [currentPopup, setCurrentPopup] = useState(null);
  const [requestPasswordLoading, setRequestPasswordLoading] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const [oldResetPassword, setOldResetPassword] = useState(null);
  const [oldResetPasswordError, setOldResetPasswordError] = useState(null);
  const [newResetPassword, setNewResetPassword] = useState(null);
  const [newResetPasswordError, setNewResetPasswordError] = useState(null);
  const [confirmResetPassword, setConfirmResetPassword] = useState(null);
  const [confirmResetPasswordError, setConfirmResetPasswordError] =
    useState(null);

  const [userRole, setUserRole] = useState(null);

  const [dronesList, setDronesList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);

  const [editOtherDrones, setEditOtherDrones] = useState(null);
  const [editOtherIndustries, setEditOtherIndustries] = useState(null);

  const [editPhoneNumber, setEditPhoneNumber] = useState(null);
  const [editPhoneNumberError, setEditPhoneNumberError] = useState(null);

  const [editIsCertified, setEditIsCertified] = useState(false);
  const [editHasInsurance, setEditHasInsurance] = useState(false);

  const [selectedDroneIds, setSelectedDroneIds] = useState(null);
  const [selectedIndustriesIds, setSelectedIndustriesIds] = useState(null);

  const [insertedLink, setInsertedLink] = useState(null);
  const [insertedLinks, setInsertedLinks] = useState([]);
  const [insertedLinkError, setInsertedLinkError] = useState(null);
  const [initialLinks, setInitialLinks] = useState(null);

  const [toggle, setToggle] = React.useState(false);
  const [profileToggle, setProfileToggle] = React.useState(false);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const [showProfileSettings, setShowProfileSettings] = React.useState(false);
  const [editProfileMode, setEditProfileMode] = React.useState(false);
  const [hideSecondSection, setHideSecondSection] = React.useState(true);
  const [viewState, setViewState] = React.useState({
    longitude: -122.43,
    latitude: 37.78,
    zoom: 12,
    pitch: 0,
  });

  useEffect(() => {
    if (!mapControlsMoved) {
      const div1 = document.querySelector('.map-container');
      const div3 = document.querySelector('.mapboxgl-control-container');

      if (div1 && div3) {
        while (div3.firstChild) {
          div1.appendChild(div3.firstChild);
        }
        setMapControlsMoved(true);
      }
    }

    if (authUserRole) {
      setUserRole(authUserRole);
      setHideSecondSection(authUserRole === 'client' && !toggle);
    }
    if (authUserIndustries) {
      setSelectedIndustriesIds(
        authUserIndustries.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      );
    }
    if (authUserDrones) {
      setSelectedDroneIds(
        authUserDrones.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      );
    }
    if (authUserOtherIndustries) {
      setEditOtherIndustries(authUserOtherIndustries);
      if (
        !selectedIndustriesIds.some((item) => item.value === -1) &&
        authUserIndustries
      ) {
        setSelectedIndustriesIds([
          ...authUserIndustries.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          {
            label: 'Other',
            value: -1,
          },
        ]);
      }
    }
    if (authUserOtherDrones) {
      setEditOtherDrones(authUserOtherDrones);
      if (
        !selectedDroneIds.some((item) => item.value === -1) &&
        authUserDrones
      ) {
        setSelectedDroneIds([
          ...authUserDrones.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          {
            label: 'Other',
            value: -1,
          },
        ]);
      }
    }
    if (authUserIsCertified) {
      setEditIsCertified(authUserIsCertified);
    }
    if (authUserHasInsurance) {
      setEditHasInsurance(authUserHasInsurance);
    }
    if (authUserPhoneNumber) {
      setEditPhoneNumber(authUserPhoneNumber);
    }
    if (authUserPortfolioVideos) {
      setInitialLinks(authUserPortfolioVideos);
    }
  }, [
    authUserDrones,
    authUserHasInsurance,
    authUserIndustries,
    authUserIsCertified,
    authUserOtherDrones,
    authUserOtherIndustries,
    authUserRole,
    authUserPhoneNumber,
    authUserPortfolioVideos,
  ]);

  const selectOptions = [
    {
      text: 'Coming soon...',
      value: 'null',
    },
  ];

  const notificationsData = [
    // {
    //   resourcetype: 'CancelContractNotification',
    //   created_at: '2024-04-04T18:04:25Z',
    //   initiator: {
    //     first_name: 'Mark',
    //     last_name: 'Zuckerberg',
    //     avatar_url:
    //       'https://img.freepik.com/free-vector/isolated-young-handsome-man-in-different-poses-on-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1908636980.1711843200&semt=ais',
    //   },
    // },
    // {
    //   resourcetype: 'JobInvitationNotification',
    //   created_at: '2024-04-04T18:04:25Z',
    //   initiator: {
    //     first_name: 'Mark',
    //     last_name: 'Zuckerberg',
    //     avatar_url:
    //       'https://img.freepik.com/free-vector/isolated-young-handsome-man-in-different-poses-on-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1908636980.1711843200&semt=ais',
    //   },
    // },
    // {
    //   resourcetype: 'ApproveProposalNotification',
    //   created_at: '2024-04-04T18:04:25Z',
    //   initiator: {
    //     first_name: 'Mark',
    //     last_name: 'Zuckerberg',
    //     avatar_url:
    //       'https://img.freepik.com/free-vector/isolated-young-handsome-man-in-different-poses-on-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1908636980.1711843200&semt=ais',
    //   },
    // },
    // {
    //   resourcetype: 'ApproveProposalNotification',
    //   created_at: '2024-04-04T18:04:25Z',
    //   initiator: {
    //     first_name: 'Mark',
    //     last_name: 'Zuckerberg',
    //     avatar_url:
    //       'https://img.freepik.com/free-vector/isolated-young-handsome-man-in-different-poses-on-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1908636980.1711843200&semt=ais',
    //   },
    // },
    {
      resourcetype: 'NegotiationNotification',
      created_at: '2024-04-04T18:04:25Z',
      initiator: {
        first_name: 'Mark',
        last_name: 'Zuckerberg',
        avatar_url:
          'https://img.freepik.com/free-vector/isolated-young-handsome-man-in-different-poses-on-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1908636980.1711843200&semt=ais',
      },
    },
  ];

  useEffect(() => {
    getDrones('').then((response) => {
      setDronesList(
        response.data.results
          .map((drone) => ({
            label: drone.name,
            value: drone.id,
          }))
          .concat({
            label: 'Other',
            value: -1,
          }),
      );
    });
    getIndustries('').then((response) =>
      setIndustriesList(
        response.data.results
          .map((industry) => ({
            label: industry.name,
            value: industry.id,
          }))
          .concat({
            label: 'Other',
            value: -1,
          }),
      ),
    );
  }, []);

  function isVideoLinkValid(value) {
    const allowedLinks = [
      'https://www.youtube.com/',
      'https://youtube.com/',
      'https://www.vimeo.com/',
      'https://vimeo.com/',
    ];

    return allowedLinks.some((allowedLink) => value.startsWith(allowedLink));
  }

  const logout = async () => {
    if (localStorage.getItem('refreshToken')) {
      await postLogout({
        refresh: localStorage.getItem('refreshToken') || '',
      });
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    auth?.setToken('');
    auth?.setUserId(null);
    auth?.setUserName('');
    navigate('/');
  };

  const saveEditPilotPortfolio = () => {
    insertedLinks.forEach(async (link) => {
      const response = await postVideoLink({
        user: auth?.userId,
        link,
      });
      auth?.setPortfolioVideos([
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...auth?.portfolioVideos,
        {
          id: response.data.id,
          name: link,
        },
      ]);
      setInitialLinks([
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...auth?.portfolioVideos,
        {
          id: response.data.id,
          name: link,
        },
      ]);
      setInsertedLinks([]);
    });
    setEditProfileMode(false);
  };

  const saveEditPilot = () => {
    const requestBody = {
      user_id: auth?.userId,
      phone_number: editPhoneNumber,
      is_certified: editIsCertified,
      is_insured: editHasInsurance,
      other_owned_drones: editOtherDrones,
      other_industries: editOtherIndustries,
    };

    if (
      selectedDroneIds &&
      !selectedDroneIds.some((item) => item.value === -1)
    ) {
      requestBody.other_owned_drones = null;
    }

    if (
      selectedIndustriesIds &&
      !selectedIndustriesIds.some((item) => item.value === -1)
    ) {
      requestBody.other_industries = null;
    }

    if (selectedDroneIds && selectedDroneIds.length > 0) {
      requestBody.owned_drones = selectedDroneIds
        .filter((item) => item.value !== -1)
        .map((item) => item.value);
    }

    if (selectedIndustriesIds && selectedIndustriesIds.length > 0) {
      requestBody.industries = selectedIndustriesIds
        .filter((item) => item.value !== -1)
        .map((item) => item.value);
    }

    patchUser(requestBody)
      .then(() => {
        auth?.setIsCertified(editIsCertified);
        auth?.setHasInsurance(editHasInsurance);
        auth?.setPhoneNumber(editPhoneNumber);
        auth?.setOtherDrones(editOtherDrones);
        auth?.setOtherIndustries(editOtherIndustries);
        auth?.setOwnedDrones(
          selectedDroneIds.map((item) => ({
            id: item.value,
            name: item.label,
          })),
        );
        auth?.setUserIndustries(
          selectedIndustriesIds.map((item) => ({
            id: item.value,
            name: item.label,
          })),
        );
        auth?.setUserIndustries(
          selectedIndustriesIds.map((item) => ({
            id: item.value,
            name: item.label,
          })),
        );
        setEditProfileMode(false);
      })
      .catch((error) => {
        fieldSetErrors(error, {
          phone_number: setEditPhoneNumberError,
        });
      });
  };

  const pilotProfile = () => (
    <>
      <div className="profile-settings-title d-flex mb-24">
        <div className="profile-settings-title-avatar my-auto">
          <img
            style={{
              width: '56px',
              height: '56px',
              borderRadius: '24px',
            }}
            src="https://w7.pngwing.com/pngs/862/646/png-transparent-beard-hipster-male-man-avatars-xmas-giveaway-icon-thumbnail.png"
            alt="user-avatar"
          />
        </div>
        <div className="profile-settings-title-info d-flex flex-column justify-content-around">
          <div className="profile-settings-title-info-name">
            {auth?.userName}
          </div>
          <div className="profile-settings-title-industries">
            {selectedIndustriesIds.map((item) => item.label).join(', ')}
          </div>
        </div>
      </div>
      <div className="profile-settings-content mb-24 d-flex justify-content-start gap-48">
        <div className="profile-settings-email">
          <div className="profile-settings-email-title">Email address:</div>
          <div className="profile-settings-email-value">{auth?.userEmail}</div>
        </div>
        <div className="profile-settings-email">
          <div className="profile-settings-email-title">Phone number:</div>
          <div className="profile-settings-email-value">
            {editPhoneNumber || 'Not specified'}
          </div>
        </div>
        {userRole === 'client' ? (
          <div className="profile-settings-email">
            <div className="profile-settings-email-title">Jobs posted:</div>
            <div className="profile-settings-email-value">0</div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="d-flex mb-24 justify-content-start gap-48">
        {userRole === 'pilot' ? (
          <>
            <div className="profile-settings-email">
              <div className="profile-settings-email-title">
                Drone insurance:
              </div>
              <div className="profile-settings-email-value">
                {auth?.hasInsurance ? 'Yes' : 'No'}
              </div>
            </div>
            <div className="profile-settings-email">
              <div className="profile-settings-email-title">
                Certified drone pilot:
              </div>
              <div className="profile-settings-email-value">
                {auth?.isCertified ? 'Yes' : 'No'}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {userRole === 'pilot' ? (
        <div className="profile-settings-content mb-24 d-flex justify-content-between">
          <div className="profile-settings-email">
            <div className="profile-settings-email-title">Equipment:</div>
            <div className="profile-settings-email-value">
              {auth?.ownedDrones.length > 0
                ? auth?.ownedDrones.map((item) => item.name).join(', ')
                : 'Not specified'}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {userRole === 'pilot' && auth?.otherDrones ? (
        <div className="profile-settings-content d-flex justify-content-between mb-24">
          <div className="profile-settings-email">
            <div className="profile-settings-email-title">Other equipment:</div>
            <div className="profile-settings-email-value">
              {auth?.otherDrones}
            </div>
          </div>
        </div>
      ) : null}
      {userRole === 'pilot' && auth?.otherIndustries ? (
        <div className="profile-settings-content d-flex justify-content-between">
          <div className="profile-settings-email">
            <div className="profile-settings-email-title">
              Other industries:
            </div>
            <div className="profile-settings-email-value">
              {auth?.otherIndustries}
            </div>
          </div>
        </div>
      ) : null}
      {userRole === 'pilot' && auth?.portfolioVideos.length > 0 ? (
        <div className="portfolio_upload_videos">
          <div className="portfolio_upload_videos_header mb-8">
            <span>Portfolio</span>
          </div>
          <div className="portfolio-videos row justify-content-between">
            {auth?.portfolioVideos.map((item) => (
              <div className="portfolio-video">
                <ReactPlayer url={item.name} width="100%" height="" controls />
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );

  const editPilotProfile = () => {
    if (profileToggle) {
      return (
        <div className="portfolio_upload_videos w-400">
          <div className="portfolio_upload_videos_header mb-8">
            <span>Videos</span>
          </div>
          <AuthInput
            placeholder="Insert link"
            currentValue={insertedLink}
            onChange={setInsertedLink}
            error={insertedLinkError}
            rightElement={
              <div
                onClick={() => {
                  setInsertedLinkError('');
                  if (!isVideoLinkValid(insertedLink)) {
                    setInsertedLinkError(
                      'Link must be a valid YouTube or Vimeo video link',
                    );
                    return;
                  }
                  setInsertedLinks([...insertedLinks, insertedLink]);
                  setInsertedLink('');
                }}
                className="portfolio-upload-videos-embed-button my-auto"
              >
                <div className="portfolio-upload-videos-embed-button-content h-100 d-flex">
                  <div className="portfolio-upload-videos-embed-button-icon d-flex flex-column justify-content-center h-100">
                    <LinkIcon />
                  </div>
                  <div className="portfolio-upload-videos-embed-button-text d-flex flex-column justify-content-center">
                    <span>Embed</span>
                  </div>
                </div>
              </div>
            }
          />
          {auth?.portfolioVideos.length > 0 ? (
            <div className="mt-8">
              {auth?.portfolioVideos.map((item) => (
                <div className="d-flex justify-content-center">
                  <div className="position-relative d-inline-flex mb-8">
                    <div
                      className="delete_video_icon"
                      onClick={() => {
                        deleteVideoLink({
                          id: item.id,
                        });
                        setInitialLinks(
                          initialLinks.filter((link) => link.id !== item.id),
                        );
                        auth?.setPortfolioVideos(
                          auth?.portfolioVideos.filter(
                            (link) => link.id !== item.id,
                          ),
                        );
                      }}
                    >
                      <CrossIcon />
                    </div>
                    <ReactPlayer
                      url={item.name}
                      width="100%"
                      height=""
                      controls
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {insertedLinks.length > 0 ? (
            <div className="mt-8">
              {insertedLinks.map((item) => (
                <div className="d-flex justify-content-center">
                  <div className="position-relative d-inline-flex mb-8">
                    <div
                      className="delete_video_icon"
                      onClick={() => {
                        setInsertedLinks(
                          insertedLinks.filter((link) => link !== item),
                        );
                      }}
                    >
                      <CrossIcon />
                    </div>
                    <ReactPlayer url={item} width="100%" height="" controls />
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="edit-profile-details">
        <div className="edit-profile-details-section-title">Details</div>
        <div className="auth_form_body_toggle_field w-200 h-100 gap-16 mb-48">
          <div className="edit-profile-field-title mb-8">Phone number:</div>
          <AuthInput
            iconComponent={PhoneIcon}
            placeholder="Your phone number"
            currentValue={editPhoneNumber}
            onChange={setEditPhoneNumber}
            error={editPhoneNumberError}
          />
        </div>
        <div className="edit-profile-details-section-title">Security</div>
        <div className="auth_form_body_toggle_field h-100 gap-16 d-flex gap-24 mb-48">
          <div className="w-200 ">
            <div className="edit-profile-field-title mb-8">
              Current password:
            </div>
            <AuthInput
              placeholder="Current password"
              textType="password"
              onChange={setOldResetPassword}
              error={oldResetPasswordError}
            />
            <div className="forgot_password_redirect d-flex justify-content-between mt-16">
              <span
                className="forgot_password_redirect_content green-text"
                onClick={() => setCurrentPopup('resetPassword')}
              >
                Forgot password?
              </span>
            </div>
          </div>
          <div className="w-200">
            <div className="edit-profile-field-title mb-8">New Password:</div>
            <AuthInput
              placeholder="New Password"
              textType="password"
              onChange={setNewResetPassword}
              error={newResetPasswordError}
            />
          </div>
          <div className="w-200">
            <div className="edit-profile-field-title mb-8">
              Confirm New Password:
            </div>
            <AuthInput
              placeholder="Confirm New Password"
              textType="password"
              onChange={setConfirmResetPassword}
              error={confirmResetPasswordError}
            />
            <div
              className="profile-settings-edit-btn d-flex flex-column justify-content-center mt-16"
              onClick={onClickResetPassword}
            >
              <div className="profile-settings-edit-text">
                {resetPasswordLoading ? (
                  <div className="btn-loader">
                    <Loader spinnerWidth={30} borderWidth={6} />
                  </div>
                ) : (
                  <span>Reset password</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="edit-profile-details-section-title">Other</div>
        <div className="auth_form_body_toggle_fields gap-24 flex-row justify-content-start">
          <div className="auth_form_body_toggle_field w-300">
            <div className="justify-content-between">
              Certified drone operator
              <SmallToggleButton
                initialToggle={auth?.isCertified}
                onToggle={() => setEditIsCertified(!editIsCertified)}
              />
            </div>
          </div>
          <div className="auth_form_body_toggle_field w-300">
            <div className="justify-content-between">
              Are you insured?
              <SmallToggleButton
                initialToggle={auth?.hasInsurance}
                onToggle={() => setEditHasInsurance(!editHasInsurance)}
              />
            </div>
          </div>
        </div>
        <div className="auth_form_body_search_field gap-24 d-flex justify-content-start">
          <span className="w-300">
            <span className="mb-8">Industry expertise</span>
            <Select
              isMulti
              defaultValue={selectedIndustriesIds}
              name="colors"
              options={industriesList}
              className="basic-multi-select mb-8"
              classNamePrefix="select"
              onChange={(currentSelectedOptions) => {
                setSelectedIndustriesIds(currentSelectedOptions);
              }}
              placeholder="Search and select"
            />
            {selectedIndustriesIds.some((item) => item.value === -1) ? (
              <AuthInput
                onChange={setEditOtherIndustries}
                currentValue={editOtherIndustries}
                placeholder="Insert here your other industry expertise"
              />
            ) : (
              ''
            )}
          </span>
          <span className="w-300">
            <span className="mb-8">Drones you own</span>
            <Select
              isMulti
              defaultValue={selectedDroneIds}
              name="colors"
              options={dronesList}
              className="basic-multi-select mb-8"
              classNamePrefix="select"
              onChange={(currentSelectedOptions) => {
                setSelectedDroneIds(
                  currentSelectedOptions.map((option) => ({
                    label: option.label,
                    value: option.value,
                  })),
                );
              }}
              placeholder="Search and select"
            />
            {selectedDroneIds.some((item) => item.value === -1) ? (
              <AuthInput
                onChange={setEditOtherDrones}
                currentValue={editOtherDrones}
                placeholder="Insert here your other industry expertise"
              />
            ) : (
              ''
            )}
          </span>
        </div>
      </div>
    );
  };

  const editClientProfile = () => (
    <div className="edit-profile-details">
      <div className="edit-profile-details-section-title">Details</div>
      <div className="auth_form_body_toggle_field w-200 h-100 gap-16 mb-48">
        <div className="edit-profile-field-title mb-8">Phone number:</div>
        <AuthInput
          iconComponent={PhoneIcon}
          placeholder="Your phone number"
          currentValue={editPhoneNumber}
          onChange={setEditPhoneNumber}
          error={editPhoneNumberError}
        />
      </div>
      <div className="edit-profile-details-section-title">Security</div>
      <div className="auth_form_body_toggle_field h-100 gap-16 d-flex gap-24">
        <div className="w-200 ">
          <div className="edit-profile-field-title mb-8">Current password:</div>
          <AuthInput
            placeholder="Current password"
            textType="password"
            onChange={setOldResetPassword}
            error={oldResetPasswordError}
          />
          <div className="forgot_password_redirect d-flex justify-content-between mt-16">
            <span
              className="forgot_password_redirect_content green-text"
              onClick={() => setCurrentPopup('resetPassword')}
            >
              Forgot password?
            </span>
          </div>
        </div>
        <div className="w-200 ">
          <div className="edit-profile-field-title mb-8">New Password:</div>
          <AuthInput
            placeholder="New Password"
            textType="password"
            onChange={setNewResetPassword}
            error={newResetPasswordError}
          />
        </div>
        <div className="w-200 ">
          <div className="edit-profile-field-title mb-8">
            Confirm New Password:
          </div>
          <AuthInput
            placeholder="Confirm New Password"
            textType="password"
            onChange={setConfirmResetPassword}
            error={confirmResetPasswordError}
          />
          <div
            className="profile-settings-edit-btn d-flex flex-column justify-content-center mt-16"
            onClick={onClickResetPassword}
          >
            <div className="profile-settings-edit-text">
              {resetPasswordLoading ? (
                <div className="btn-loader">
                  <Loader spinnerWidth={30} borderWidth={6} />
                </div>
              ) : (
                <span>Reset password</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const onClickSendResetLink = () => {
    setRequestPasswordLoading(true);
    requestResetPassword({
      email: auth?.userEmail,
    }).then(() => {
      setCurrentPopup('resetLinkSent');
      setRequestPasswordLoading(false);
    });
  };

  // eslint-disable-next-line consistent-return
  const getCurrentPopup = () => {
    if (currentPopup === 'resetPassword') {
      return (
        <div className="profile-settings-popup">
          <div className="profile-settings-popup-container mx-auto">
            <div className="forgot_password_header">
              <div className="forgot_password_header_icon_container">
                <Arrow
                  onClick={() => setCurrentPopup(null)}
                  className="forgot_password_header_icon"
                />
              </div>
              Reset password
            </div>
            <div className="forgot_password_button">
              <MainButton
                text="Send reset link"
                buttonColor="#C3FF01"
                onClick={onClickSendResetLink}
                loading={requestPasswordLoading}
              />
            </div>
          </div>
        </div>
      );
    }
    if (currentPopup === 'resetLinkSent') {
      return (
        <div className="profile-settings-popup">
          <div className="profile-settings-popup-container mx-auto">
            <div className="forgot_password_header">
              <div className="forgot_password_header_icon_container">
                <Arrow
                  onClick={() => setCurrentPopup(null)}
                  className="forgot_password_header_icon"
                />
              </div>
              Success!
            </div>
            <div className="forgot_password_success_form_body">
              <div className="forgot_password_success_form_body_content">
                Reset letter sent to <span>{auth?.userEmail}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (currentPopup === 'passwordUpdated') {
      return (
        <div className="profile-settings-popup">
          <div className="profile-settings-popup-container mx-auto">
            <div className="forgot_password_header">
              <div className="forgot_password_header_icon_container">
                <Arrow
                  onClick={() => setCurrentPopup(null)}
                  className="forgot_password_header_icon"
                />
              </div>
              Success!
            </div>
            <div className="forgot_password_success_form_body">
              <div className="forgot_password_success_form_body_content">
                Password successfully changed
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const onClickResetPassword = () => {
    setResetPasswordLoading(true);
    setOldResetPasswordError('');
    setConfirmResetPasswordError('');
    setNewResetPasswordError('');
    if (newResetPassword !== confirmResetPassword) {
      setConfirmResetPasswordError('Passwords do not match');
      setNewResetPasswordError(' ');
      return;
    }
    postChangePassword({
      old_password: oldResetPassword,
      new_password: newResetPassword,
    })
      .then(() => {
        setCurrentPopup('passwordUpdated');
      })
      .catch((error) => {
        setNewResetPasswordError(' ');
        setConfirmResetPasswordError(' ');
        const isErrorFields = fieldSetErrors(error, {
          detail: setOldResetPasswordError,
          old_password: setOldResetPasswordError,
          new_password: setNewResetPasswordError,
        });
        if (!isErrorFields) {
          setOldResetPasswordError(getApiError(error));
        }
      })
      .finally(() => setResetPasswordLoading(false));
  };

  return (
    <div className="main-page-container vw-100 vh-100 position-relative">
      <div className="row h-72 d-flex justify-content-center position-relative z-3">
        <div className="col-3 mh-72 pl-0">
          <ToggleButton
            leftBtnData={{
              text: userRole === 'pilot' ? 'Dashboard' : 'Pilots',
              iconComponent:
                userRole === 'pilot' ? DashboardIcon : JoystickIcon,
            }}
            rightBtnData={{
              text: userRole === 'pilot' ? 'Find Work' : 'Work',
              iconComponent: FindWorkIcon,
            }}
            toggle={toggle}
            handleToggleChange={() => {
              setHideSecondSection(toggle && userRole === 'client');
              setToggle(!toggle);
            }}
            buttonStyle="Dashboard"
          />
        </div>
        <div className="col-9 row justify-content-between pl-24 pr-12">
          <div className="col z-1 pl-0">
            <div className="d-flex flex-column justify-content-center h-100 filters-container">
              <div className="filters-container-content d-flex gap-8 justify-content-between">
                <AuthSelectField
                  placeholder="Work type"
                  selectOptions={selectOptions}
                  onChange={() => null}
                  style={{ flex: '1 1 0' }}
                />
                <AuthSelectField
                  placeholder="Area"
                  selectOptions={selectOptions}
                  onChange={() => null}
                  style={{ flex: '1 1 0' }}
                />
                <AuthSelectField
                  placeholder="Buget"
                  selectOptions={selectOptions}
                  onChange={() => null}
                  style={{ flex: '1 1 0' }}
                />
                <CustomButton
                  iconComponent={TuneIcon}
                  text="More"
                  style={{ flex: '1 1 0' }}
                />
                <CustomButton
                  iconComponent={ResetIcon}
                  text="Reset filters"
                  style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    flex: '0',
                    minWidth: '162px',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="profile-button d-flex flex-column justify-content-center col-2">
            <div className="profile-button-notifications d-flex justify-content-center">
              <div
                className="notification-icon position-relative d-flex flex-column justify-content-center cursor-pointer"
                onClick={() => {
                  setShowNotifications(!showNotifications);
                }}
              >
                {showNotifications ? (
                  <div className="close-notifications d-flex justify-content-center">
                    <CrossIcon />
                  </div>
                ) : (
                  <>
                    <NotificationIcon />
                    <div className="profile-button-notifications-count position-absolute">
                      {notificationsData.length}
                    </div>
                  </>
                )}
              </div>
              <div
                className={showProfileSettings ? 'dark-user-avatar' : ''}
                onClick={() => setShowProfileSettings(!showProfileSettings)}
              >
                {showProfileSettings ? (
                  <CrossIcon className="dark-user-avatar-close" />
                ) : null}
                <img
                  style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '24px',
                    cursor: 'pointer',
                  }}
                  src="https://w7.pngwing.com/pngs/862/646/png-transparent-beard-hipster-male-man-avatars-xmas-giveaway-icon-thumbnail.png"
                  alt="user-avatar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-section pt-24 row">
        <div className="works-container col-3 pr-0">
          <div className="h-72 position-relative z-1">
            {(() => {
              if (userRole === 'client') {
                if (toggle) {
                  return <WorkHeader text="Projects" />;
                }
                return <WorkHeader text="Pilots" />;
              }
              if (toggle) {
                return <WorkHeader text="Invites" />;
              }
              return <WorkHeader text="Contracts" />;
            })()}
          </div>
          <div className="mt-16 mh-90 overflow-y-scroll no-scrollbar">
            {(() => {
              if (userRole === 'client') {
                if (hideSecondSection) {
                  return <Jobs />;
                }
                return <Contracts />;
              }
              if (toggle) {
                return <Invites />;
              }
              return <Contracts />;
            })()}
          </div>
        </div>
        {!hideSecondSection ? (
          <div
            className="works-container col-3 pl-24 pr-24"
            style={{ color: 'white' }}
          >
            <div className="h-72 position-relative z-1">
              {(() => {
                if (userRole === 'client') {
                  if (toggle) {
                    return <WorkHeader text="Invites" />;
                  }
                  return null;
                }
                if (toggle) {
                  return <WorkHeader text="Jobs" />;
                }
                return <WorkHeader text="Proposals" />;
              })()}
            </div>
            <div className="mt-16 mh-90 overflow-y-scroll no-scrollbar">
              {(() => {
                if (userRole === 'client') {
                  if (toggle) {
                    return <Proposals />;
                  }
                  return null;
                }
                if (toggle) {
                  return <Jobs />;
                }
                return <Proposals />;
              })()}
            </div>
          </div>
        ) : (
          ''
        )}
        <div
          className={`${hideSecondSection ? 'col-9 pl-24' : 'col-6'} position-relative p-0 pl-0 pr-24 map-wrapper`}
        >
          {showNotifications ? (
            <div className="notifications-list position-absolute d-flex flex-column justify-content-sm-between gap-8 z-3">
              <Notification notificationsData={notificationsData} />
            </div>
          ) : (
            ''
          )}
          {showProfileSettings ? (
            <div className="profile-settings-wrapper z-2">
              <div className="profile-settings">
                {getCurrentPopup()}

                <div className="profile-settings-header d-flex flex-column justify-content-center">
                  <span>My profile</span>
                </div>
                <div className="edit-profile-toggle-wrapper">
                  <div className="edit-profile-toggle-container mx-auto">
                    {editProfileMode && userRole === 'pilot' ? (
                      <ToggleButton
                        leftBtnData={{
                          text: 'Details',
                          iconComponent: ClientIcon,
                        }}
                        rightBtnData={{
                          text: 'Portfolio',
                          iconComponent: FindWorkIcon,
                        }}
                        toggle={profileToggle}
                        handleToggleChange={() =>
                          setProfileToggle(!profileToggle)
                        }
                        buttonStyle="Profile"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div
                  className={`profile-settings-title-wrapper h-100 w-100 ${editProfileMode && userRole === 'pilot' ? 'mt-104 edit-profile-wrapper' : ''}`}
                >
                  {editProfileMode && userRole === 'pilot'
                    ? editPilotProfile()
                    : ''}
                  {editProfileMode && userRole === 'client'
                    ? editClientProfile()
                    : ''}

                  {!editProfileMode ? pilotProfile() : ''}
                </div>
                {editProfileMode ? (
                  <div className="profile-settings-buttons d-flex justify-content-end gap-16">
                    <div
                      className="profile-settings-logout-btn d-flex flex-column justify-content-center"
                      onClick={() => {
                        setEditPhoneNumber(authUserPhoneNumber);
                        setEditIsCertified(authUserIsCertified);
                        setEditHasInsurance(authUserHasInsurance);
                        setSelectedDroneIds(
                          authUserDrones.map((item) => ({
                            label: item.name,
                            value: item.id,
                          })),
                        );
                        setSelectedIndustriesIds(
                          authUserIndustries.map((item) => ({
                            label: item.name,
                            value: item.id,
                          })),
                        );
                        if (
                          !selectedIndustriesIds.some(
                            (item) => item.value === -1,
                          ) &&
                          authUserIndustries &&
                          authUserOtherIndustries
                        ) {
                          setSelectedIndustriesIds([
                            ...authUserIndustries.map((item) => ({
                              label: item.name,
                              value: item.id,
                            })),
                            {
                              label: 'Other',
                              value: -1,
                            },
                          ]);
                        }

                        if (
                          !selectedDroneIds.some((item) => item.value === -1) &&
                          authUserDrones &&
                          authUserOtherDrones
                        ) {
                          setSelectedDroneIds([
                            ...authUserDrones.map((item) => ({
                              label: item.name,
                              value: item.id,
                            })),
                            {
                              label: 'Other',
                              value: -1,
                            },
                          ]);
                        }
                        setEditProfileMode(!editProfileMode);
                      }}
                    >
                      <div className="profile-settings-logout-text">Cancel</div>
                    </div>
                    <div
                      className="profile-settings-save-btn d-flex flex-column justify-content-center"
                      onClick={() => {
                        setOldResetPasswordError('');
                        setConfirmResetPasswordError('');
                        setNewResetPasswordError('');
                        if (profileToggle) {
                          saveEditPilotPortfolio();
                        } else {
                          saveEditPilot();
                        }
                      }}
                    >
                      <div className="profile-settings-save-text">
                        Save changes
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="profile-settings-buttons d-flex justify-content-between">
                    <div
                      className="profile-settings-logout-btn d-flex flex-column justify-content-center"
                      onClick={logout}
                    >
                      <div className="profile-settings-logout-text">
                        Log out
                      </div>
                    </div>
                    <div
                      className="profile-settings-edit-btn d-flex flex-column justify-content-center"
                      onClick={() => {
                        setEditProfileMode(!editProfileMode);
                      }}
                    >
                      <div className="profile-settings-edit-text">
                        Edit profile
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="map-container h-100 position-relative pl-24">
            <Map
              longitude={viewState.longitude}
              latitude={viewState.latitude}
              zoom={viewState.zoom}
              pitch={viewState.pitch}
              onMove={onMove}
              style={{
                height: '100vh',
                width: '100vw',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              mapStyle="mapbox://styles/mapbox/dark-v11"
              mapboxAccessToken={mapboxToken}
            >
              <NavigationControl position="bottom-right" showCompass={false} />
              <GeolocateControl position="bottom-right" />
            </Map>
          </div>
        </div>
      </div>
      <Map
        id="background-map"
        longitude={viewState.longitude}
        latitude={viewState.latitude}
        zoom={viewState.zoom}
        pitch={viewState.pitch}
        padding={{
          top: 228,
          left: hideSecondSection ? windowWidth / 1.835 : windowWidth,
        }}
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -2,
        }}
        mapStyle="mapbox://styles/mapbox/dark-v11"
        mapboxAccessToken={mapboxToken}
      />
    </div>
  );
};
