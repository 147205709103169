import * as React from 'react';
import { ContractIcon, Email } from '../../../resources/icons';
import datetimeFormatter from '../../../utils/datetimeFormatter';

export const WorkList = ({ worksList, workType }) => {
  const renderWorkItemIcon = () => {
    switch (workType) {
      // client
      case 'pilot':
        return null;
      case 'projects':
        return null;
      case 'client-proposal':
        return null;

      // pilot
      case 'contract':
        return (
          <div className="icon contract d-flex justify-content-center my-auto">
            <ContractIcon />
          </div>
        );
      case 'pilot-proposal':
        return '';
      case 'invite':
        return (
          <div className="icon invite d-flex justify-content-center my-auto">
            <Email />
          </div>
        );
      case 'job':
        return '';
      default:
        return 'Error';
    }
  };

  const getWorkColorClassName = () => {
    switch (workType) {
      case 'contract':
        return 'contract-red-color';
      case 'proposal':
        return 'proposal-green-color';
      case 'invite':
        return 'invite-purple-color';
      case 'job':
        return 'job-green-color';
      case 'pilot-proposal':
        return 'job-green-color';
      default:
        return '';
    }
  };

  const renderEndComponent = (workItem) => {
    switch (workType) {
      case 'contract':
        return '';
      case 'proposal':
        return (
          <div className="estimated-cost d-flex flex-column justify-content-center">
            {/* <div className="usd-cost">${workItem.estimated_cost}</div> */}
            {/* <div className="cost-text">estimation</div> */}
          </div>
        );
      case 'invite':
        return '';
      case 'job':
        return '';
      default:
        return '';
    }
  };

  const hasIcon = () => ['contract', 'invite'].includes(workType);

  return (
    <div className="work-list d-flex flex-column justify-content-start gap-16">
      {worksList.map((workItem) => (
        <div className="work-item-container d-flex flex-column justify-content-center cursor-pointer">
          <div
            className={`work-item d-flex ${hasIcon() ? 'justify-content-start' : 'justify-content-between'}`}
          >
            {renderWorkItemIcon()}
            <div className="work-info d-flex flex-column justify-content-between">
              <div className="work-title">{workItem.title}</div>
              <div className="work-secondary-info d-flex justify-content-start">
                <div
                  className={`
                  ${getWorkColorClassName()}
                  work-category d-flex flex-column justify-content-start
                  `}
                >
                  <span>{workItem.category}</span>
                </div>
                {/* <div className="work-datetime d-flex flex-column justify-content-end"> */}
                {/*   <span>{datetimeFormatter(workItem.created_at)}</span> */}
                {/* </div> */}
              </div>
            </div>
            {renderEndComponent(workItem)}
          </div>
        </div>
      ))}
    </div>
  );
};
