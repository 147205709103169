import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShortArrow, UserIcon } from '../../resources/icons';
import { useAuth } from '../../contexts/authContext';
import postLogout from '../../api/postLogout';

export const ProfileButton = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    if (localStorage.getItem('refreshToken')) {
      await postLogout({
        refresh: localStorage.getItem('refreshToken') || '',
      });
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    auth?.setToken('');
    auth?.setUserId(null);
    auth?.setUserName('');
    navigate('/');
  };

  return (
    <div className="profile-button">
      <div className="profile-button cursor-pointer d-flex flex-column justify-content-center">
        <div
          className="profile-button-content"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="profile-button-icon d-flex flex-column justify-content-center">
            <UserIcon className="mx-auto" />
          </div>
          <div className="profile-button-username">
            <span>{auth?.userName}</span>
          </div>
          <div className="profile-button-arrow h-100">
            <ShortArrow
              style={{
                height: '15px',
                transform: showDropdown ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`profile-button-dropdown d-flex flex-column ${showDropdown ? '' : 'd-none'}`}
      >
        {/* <div className="profile-button-dropdown-item d-flex flex-column justify-content-center cursor-pointer"> */}
        {/*   <span>Settings</span> */}
        {/* </div> */}
        <div
          className="profile-button-dropdown-item d-flex flex-column justify-content-center cursor-pointer"
          onClick={logout}
        >
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};
