import * as React from 'react';
import { CSSProperties } from 'react';

interface CustomButtonProps {
  iconComponent: React.ElementType;
  text: string;
  style?: CSSProperties | undefined;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  iconComponent: Component,
  style = {},
}) => (
  <div className="custom-button-wrapper" style={style}>
    <div className="d-flex custom-button-container h-100 gap-8">
      <div className="d-flex flex-column justify-content-center custom-button-icon">
        <Component />
      </div>
      <div className="d-flex flex-column justify-content-center custom-button-text">
        {text}
      </div>
    </div>
  </div>
);
