import { axiosInstance } from './axios';

const getDrones = async (search?: string) => {
  const params = {
    search,
  };

  return axiosInstance.get('/nomenclatures/drones', { params });
};

export default getDrones;
