import * as React from 'react';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { axiosInstance } from '../api/axios';
import getMe from '../api/me';

interface AuthContextProps {
  userId: number | null;
  setUserId: (id: number | null) => void | null;
  token: string | null;
  setToken: (token: string) => void | null;
  userName: string;
  setUserName: (username: string) => void;
  userRole: string;
  setUserRole: (role: string) => void;
  userEmail: string;
  setUserEmail: (email: string) => void;
  hasInsurance: boolean;
  setHasInsurance: (insurance: boolean) => void;
  isCertified: boolean;
  setIsCertified: (certified: boolean) => void;
  otherDrones: string;
  setOtherDrones: (drones: string) => void;
  ownedDrones: Item[];
  setOwnedDrones: (drones: Item[]) => void;
  userIndustries: Item[];
  setUserIndustries: (industries: Item[]) => void;
  portfolioVideos: Item[];
  setPortfolioVideos: (videos: Item[]) => void;
  otherIndustries: string;
  setOtherIndustries: (industries: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

interface AuthProviderProps {
  children?: ReactNode;
}

interface Item {
  id: number;
  name: string;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userId, setUserId_] = useState<number | null>(null);
  const [token, setToken_] = useState(localStorage.getItem('accessToken'));
  const [userName, setUserName_] = useState('');
  const [userRole, setUserRole_] = useState('');
  const [userEmail, setUserEmail_] = useState('');
  const [hasInsurance, setHasInsurance_] = useState(false);
  const [isCertified, setIsCertified_] = useState(false);
  const [otherDrones, setOtherDrones_] = useState('');
  const [ownedDrones, setOwnedDrones_] = useState<Item[]>([]);
  const [userIndustries, setUserIndustries_] = useState<Item[]>([]);
  const [portfolioVideos, setPortfolioVideos_] = useState<Item[]>([]);
  const [otherIndustries, setOtherIndustries_] = useState('');
  const [phoneNumber, setPhoneNumber_] = useState('');

  const setToken = (newToken) => {
    setToken_(newToken);
  };

  const setUserId = (userid) => {
    setUserId_(userid);
  };

  const setUserName = (username) => {
    setUserName_(username);
  };

  const setUserRole = (role) => {
    setUserRole_(role);
  };

  const setUserEmail = (email) => {
    setUserEmail_(email);
  };

  const setHasInsurance = (insurance) => {
    setHasInsurance_(insurance);
  };

  const setIsCertified = (certified) => {
    setIsCertified_(certified);
  };

  const setOtherDrones = (drones) => {
    setOtherDrones_(drones);
  };

  const setOwnedDrones = (drones) => {
    setOwnedDrones_(drones);
  };

  const setUserIndustries = (industries) => {
    setUserIndustries_(industries);
  };

  const setPortfolioVideos = (videos) => {
    setPortfolioVideos_(videos);
  };

  const setOtherIndustries = (industries) => {
    setOtherIndustries_(industries);
  };

  const setPhoneNumber = (newPhoneNumber) => {
    setPhoneNumber_(newPhoneNumber);
  };

  const updateUser = async () => {
    const response = await getMe();
    setUserRole(response.data.role);
    setUserId(response.data.id);
    setUserName(`${response.data.first_name} ${response.data.last_name}`);
    setUserRole(response.data.role);
    setUserEmail(response.data.email);
    setHasInsurance(response.data.is_insured);
    setIsCertified(response.data.is_certified);
    setOtherDrones(response.data.other_owned_drones);
    setOtherIndustries(response.data.other_industries);
    setPhoneNumber(response.data.phone_number);
    setOwnedDrones(
      response.data.owned_drones.map((drone) => ({
        id: drone.id,
        name: drone.name,
      })),
    );
    setUserIndustries(
      response.data.industries.map((industry) => ({
        id: industry.id,
        name: industry.name,
      })),
    );
    setPortfolioVideos(
      response.data.video_links.map((video) => ({
        id: video.id,
        name: video.link,
      })),
    );
  };

  useEffect(() => {
    if (!localStorage.getItem('refreshToken')) {
      return;
    }

    updateUser();

    if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('accessToken', token);
    } else {
      delete axiosInstance.defaults.headers.common.Authorization;
      localStorage.removeItem('accessToken');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const contextValue = useMemo(
    () => ({
      userId,
      setUserId,
      token,
      setToken,
      userName,
      setUserName,
      userRole,
      setUserRole,
      userEmail,
      setUserEmail,
      hasInsurance,
      setHasInsurance,
      isCertified,
      setIsCertified,
      otherDrones,
      setOtherDrones,
      ownedDrones,
      setOwnedDrones,
      userIndustries,
      setUserIndustries,
      portfolioVideos,
      setPortfolioVideos,
      otherIndustries,
      setOtherIndustries,
      phoneNumber,
      setPhoneNumber,
    }),
    [
      hasInsurance,
      isCertified,
      otherDrones,
      otherIndustries,
      ownedDrones,
      phoneNumber,
      portfolioVideos,
      token,
      userEmail,
      userId,
      userIndustries,
      userName,
      userRole,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
