import { axiosInstance } from './axios';

export interface PatchUserData {
  user_id: number;
  is_certified?: boolean;
  is_insured?: boolean;
  owned_drones?: number[];
  industries?: number[];
  services?: number[];
  phone_number?: string;
  other_owned_drones?: string;
  other_industries?: string;
}

const patchUser = async (data: PatchUserData) =>
  axiosInstance.patch(`/users/users/${data.user_id}`, data);

export default patchUser;
