import { axiosInstance } from './axios';

export interface requestResetPasswordData {
  email: string;
}

const requestResetPassword = async (data: requestResetPasswordData) =>
  axiosInstance.post('/users/users/request-reset-password', data);

export default requestResetPassword;
