import * as React from 'react';
import { Loader } from '../../Loader';

interface MainButtonProps {
  text: string;
  onClick: () => void;
  buttonColor?: string;
  textColor?: string;
  cursor?: string;
  loading?: boolean;
}

export const MainButton: React.FC<MainButtonProps> = ({
  text,
  buttonColor,
  textColor,
  cursor,
  onClick,
  loading,
}) => (
  <div
    className="auth_main_button_container"
    style={{
      backgroundColor: buttonColor,
      cursor,
    }}
    onClick={loading ? () => null : onClick}
  >
    <div className="auth_main_button_content">
      <div
        className="auth_main_button_text d-flex justify-content-center"
        style={{ color: textColor }}
      >
        {loading ? (
          <div className="btn-loader">
            <Loader spinnerWidth={30} borderWidth={6} />
          </div>
        ) : (
          <span>{text}</span>
        )}
      </div>
    </div>
  </div>
);
