import { axiosInstance } from './axios';

export interface resetPasswordData {
  token: string;
  password: string;
}

const resetPassword = async (data: resetPasswordData) =>
  axiosInstance.post('/users/users/reset-password', data);

export default resetPassword;
