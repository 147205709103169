import { axiosInstance } from './axios';

export interface RefreshTokenData {
  refresh: string;
}

const postRefreshToken = async (data: RefreshTokenData) =>
  axiosInstance.post('/auth/jwt/refresh', data);

export default postRefreshToken;
