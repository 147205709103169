import * as React from 'react';

export const HomeTitle: React.FC = () => (
  <div className="home_title">
    <div className="home_title_wrapper">
      <div className="home_title_main_text">
        <div
          style={{ color: '#C3FF01' }}
          className="h-100 d-flex justify-content-center align-items-center"
        >
          Connecting expert Drone pilots
        </div>
        <div className="h-100 d-flex justify-content-center align-items-center">
          with Clients on a Global Scale
        </div>
      </div>
      <div className="home_title_description_text h-100 d-flex justify-content-center align-items-center">
        Simply enter the postcode corresponding to the location of your aerial
        request, select the number of quotes you would like to receive and our
        professional members will be in touch with you.
      </div>
    </div>
  </div>
);
