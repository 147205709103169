import * as React from 'react';
import { Facebook, Linkedin, XLogo, Youtube } from '../../../resources/icons';

export const HomeFooter: React.FC = () => (
  <div className="home_footer">
    <div className="rights_reserved">
      © 2024 CaptureHub, Inc. All rights reserved
    </div>
    <div className="footer_social_links">
      <div className="footer_social_link">
        <div className="footer_social_link_container">
          <Youtube />
        </div>
      </div>
      <div className="footer_social_link">
        <div className="footer_social_link_container">
          <Linkedin />
        </div>
      </div>
      <div className="footer_social_link">
        <div className="footer_social_link_container">
          <XLogo />
        </div>
      </div>
      <div className="footer_social_link">
        <div className="footer_social_link_container">
          <Facebook />
        </div>
      </div>
    </div>
  </div>
);
