import * as React from 'react';
import { HomeHeader } from './HomeHeader';
import { HomeTitle } from './HomeTitle';
import { HomeFooter } from './HomeFooter';
import { RoleInfo } from './RoleInfo';
import { CardCarousel } from './CardCarousel';

export const HomePage: React.FC = () => (
  <div className="home_page__wrapper position-relative">
    <svg
      viewBox="100% 100% 100% 100%"
      xmlns="http://www.w3.org/2000/svg"
      className="background_noise"
    >
      <filter id="noiseFilter">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="1"
          numOctaves="6"
          stitchTiles="stitch"
        />
      </filter>
      <rect width="100%" height="100%" filter="url(#noiseFilter)" />
    </svg>
    <div className="home_page__background">
      <div className="home_page__gradient">
        <div className="home_page__gradient_background">
          <div className="home_page__content">
            <HomeHeader />
            <HomeTitle />
            <CardCarousel />
            <RoleInfo />
            <HomeFooter />
          </div>
        </div>
      </div>
    </div>
    <svg
      viewBox="100% 100% 100% 100%"
      xmlns="http://www.w3.org/2000/svg"
      className="background_noise"
      style={{
        transform: 'rotate(180deg)',
        bottom: 0,
        top: 'unset',
        zIndex: -3,
      }}
    >
      <filter id="noiseFilter">
        <feTurbulence
          type="fractalNoise"
          baseFrequency="1"
          numOctaves="6"
          stitchTiles="stitch"
        />
      </filter>
      <rect width="100%" height="100%" filter="url(#noiseFilter)" />
    </svg>
  </div>
);
