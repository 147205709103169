import { toast } from 'react-toastify';

export const handleApiError = (request, ignoreStatusError = true) => {
  if (
    typeof request.response.data !== 'string' &&
    request.response.data.detail
  ) {
    toast.error(request.response.data.detail);
  } else if (!ignoreStatusError) {
    toast.error(`Error ${request.response.status}`);
  }
};

export const getApiError = (request) => {
  if (
    typeof request.response.data !== 'string' &&
    request.response.data.detail
  ) {
    return request.response.data.detail;
  }
  return `Error ${request.response.status}`;
};

export const fieldSetErrors = (request, errorFieldState) => {
  let isErrorFields = false;
  Object.keys(errorFieldState).forEach((key) => {
    if (Object.keys(request.response.data).includes(key)) {
      errorFieldState[key](request.response.data[key]);
      isErrorFields = true;
    }
  });
  return isErrorFields;
};
