import * as React from 'react';
import { useState } from 'react';
import { SignupForm } from './SignupForm';
import { DetailsForm } from './DetailsForm';

interface SignupProps {
  onClickBack: () => void;
}

export interface SignupFormData {
  email: string;
  role: string;
  first_name: string;
  last_name: string;
  business_name?: string | null;
  password: string;
}

export const Signup: React.FC<SignupProps> = ({ onClickBack }) => {
  type Variant = 'SignupForm' | 'DetailsForm' | 'PleaseActivateAccount';

  const [currentForm, setCurrentForm] = useState<Variant>('SignupForm');
  const [registerData, setRegisterData] = useState<SignupFormData>({
    email: '',
    role: '',
    first_name: '',
    last_name: '',
    business_name: null,
    password: '',
  });

  return (
    <div className="signup_container w-100">
      <div style={currentForm === 'SignupForm' ? {} : { display: 'none' }}>
        <SignupForm
          onPilotSubmit={() => setCurrentForm('DetailsForm')}
          onClientSubmit={() => setCurrentForm('PleaseActivateAccount')}
          onClickBack={onClickBack}
          setRegisterData={setRegisterData}
        />
      </div>
      <div style={currentForm === 'DetailsForm' ? {} : { display: 'none' }}>
        <DetailsForm
          data={registerData}
          onSubmit={() => setCurrentForm('PleaseActivateAccount')}
        />
      </div>
      <div
        style={
          currentForm === 'PleaseActivateAccount' ? {} : { display: 'none' }
        }
      >
        <div className="auth_form_container">
          <div className="forgot_password_content">
            <div className="forgot_password_header">Activation link</div>
            <div className="forgot_password_success_form_body">
              <div className="forgot_password_success_form_body_content">
                Activation link sent to your email
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
