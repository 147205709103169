import * as React from 'react';

interface ForgotPasswordLetterSentProps {
  sentToEmail: string;
}

export const ForgotPasswordLetterSent: React.FC<
  ForgotPasswordLetterSentProps
> = ({ sentToEmail }) => (
  <div className="forgot_password_content">
    <div className="forgot_password_header">Reset password</div>
    <div className="forgot_password_success_form_body">
      <div className="forgot_password_success_form_body_content">
        Reset letter sent to <span>{sentToEmail}</span>
      </div>
    </div>
  </div>
);
