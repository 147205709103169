import * as React from 'react';
import { useState } from 'react';
import { ForgotPasswordInputForm } from './ForgotPasswordInputForm';
import { ForgotPasswordLetterSent } from './ForgotPasswordSuccessForm';
import { ForgotPasswordResetForm } from './ForgotPasswordResetForm';
import { ForgotPasswordSuccessResetForm } from './ForgotPasswordSuccessResetForm';

interface ForgotPasswordProps {
  onClickBack: () => void;
  initialForm:
    | 'requestResetPassword'
    | 'resetLetterSent'
    | 'resetPassword'
    | 'passwordChanged';
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  onClickBack,
  initialForm,
}) => {
  type Variant =
    | 'requestResetPassword'
    | 'resetLetterSent'
    | 'resetPassword'
    | 'passwordChanged';

  const [currentForm, setCurrentForm] = useState<Variant>(initialForm);

  const [email, setEmail] = useState('');

  return (
    <div className="forgot_password_form">
      <div style={currentForm === 'passwordChanged' ? {} : { display: 'none' }}>
        <ForgotPasswordSuccessResetForm />
      </div>

      <div style={currentForm === 'resetPassword' ? {} : { display: 'none' }}>
        <ForgotPasswordResetForm
          onSubmit={() => {
            setCurrentForm('passwordChanged');
          }}
        />
      </div>

      <div
        style={
          currentForm === 'requestResetPassword' ? {} : { display: 'none' }
        }
      >
        <ForgotPasswordInputForm
          onSubmit={() => setCurrentForm('resetLetterSent')}
          onClickBack={onClickBack}
          setEmail={setEmail}
        />
      </div>

      <div style={currentForm === 'resetLetterSent' ? {} : { display: 'none' }}>
        <ForgotPasswordLetterSent sentToEmail={email} />
      </div>
    </div>
  );
};
