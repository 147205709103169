import * as React from 'react';
import { Email } from '../../../../resources/icons';

export const Notification = ({ notificationsData }) => {
  const renderText = (notificationItem) => {
    switch (notificationItem.resourcetype) {
      case 'CancelContractNotification':
        return (
          <div className="title">
            {notificationItem.initiator.first_name}{' '}
            {notificationItem.initiator.last_name.charAt(0)}. have canceled{' '}
            <span className="green-text">the Contract</span>
          </div>
        );
      case 'JobInvitationNotification':
        return (
          <div className="title">
            You have received a{' '}
            <span className="green-text">Job Invitation</span>
          </div>
        );
      case 'ApproveProposalNotification':
        return <div className="title">Claire F. approved your proposal</div>;
      case 'NegotiationNotification':
        return (
          <>
            <div className="title">Coming soon...</div>
            <div className="description text-start">
              <div className="green-text">Sorry, we are working on this</div>{' '}
            </div>
            {/* <div className="title">You have received a negotiation</div> */}
            {/* <div className="description text-start"> */}
            {/*   You have received a budget negotiation on the{' '} */}
            {/*   <div className="green-text"> */}
            {/*     Solar panels professional shooting session */}
            {/*   </div>{' '} */}
            {/*   invitation. */}
            {/* </div> */}
          </>
        );
      default:
        return 'Error';
    }
  };

  return (
    <>
      {notificationsData.map((item) => (
        <div className="notification-item d-flex justify-content-start gap-16">
          <div className="icon">
            {[
              'CancelContractNotification',
              'ApproveProposalNotification',
            ].includes(item.resourcetype) ? (
              <img
                src={item.initiator.avatar_url}
                alt="notification-icon"
                height="56px"
                width="56px"
              />
            ) : (
              <div className="notification-svg d-flex justify-content-center">
                <Email />
              </div>
            )}
          </div>
          <div className="content d-flex flex-column justify-content-center gap-16">
            {renderText(item)}
          </div>
        </div>
      ))}
    </>
  );
};
