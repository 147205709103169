import { axiosInstance } from './axios';

export interface RefreshTokenData {
  token: string;
}

const postActivateAccount = async (data: RefreshTokenData) =>
  axiosInstance.post('/users/users/activate-account', data);

export default postActivateAccount;
