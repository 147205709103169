import * as React from 'react';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../resources/icons';

export const HomeHeaderButtons: React.FC = () => (
  <div className="row home_header_buttons">
    <div className="col header__text get_hired my-auto">
      <Link to="/app">Find a Pilot</Link>
    </div>
    <div className="col header__text find_pilot my-auto">
      <Link to="/app">
        <div className="header__text__container">
          <div className="header__text__content">
            <span className="header__text__title">Register</span>
            <div className="find_pilot_icon">
              <Arrow />
            </div>
          </div>
        </div>
      </Link>
    </div>
  </div>
);
