import * as React from 'react';
import { Link } from 'react-router-dom';
import { Arrow, Drone, Person } from '../../../resources/icons';

export const RoleInfo: React.FC = () => {
  const [isDroneOptionSelected, setIsDroneOptionSelected] =
    React.useState(true);

  const droneRoleInfo = [
    {
      id: 1,
      title: 'Expand Your Horizons with a Thriving Client Network',
      description:
        'Connect with clients actively seeking skilled drone pilots for a variety of projects. Whether you specialize in aerial photography, surveying, or inspections, an extensive client database ensures that your expertise reaches the right audience',
      image: 'images/whoAreYou/image1.png',
    },
    {
      id: 2,
      title: 'Boost Your Business with Quality Job Opportunities',
      description:
        'Discover a streamlined process to find new gigs and grow your drone pilot business. Create a profile, list your services, and start receiving job requests from clients in need of professional drone operations. Maximize your reach and minimize downtime with a user-friendly interface',
      image: 'images/whoAreYou/image2.png',
    },
    {
      id: 3,
      title: 'Gain Visibility and Build Your Professional Reputation',
      description:
        'Showcase your skills and portfolio to a wide array of potential clients. Our platform not only connects you with immediate job opportunities but also helps you build long-term relationships with clients. Get rated and reviewed to enhance your credibility and attract even more business',
      image: 'images/whoAreYou/image3.png',
    },
  ];

  const clientRoleInfo = [
    {
      id: 1,
      title: 'Find the Perfect Drone Pilot for Your Needs',
      description:
        'Easily locate and hire experienced drone pilots for your specific project requirements. Input your location, describe your job, and receive quotes from qualified professionals in your area. Our platform makes it simple to connect with the right talent for your aerial tasks',
      image: 'images/whoAreYou/image4.png',
    },
    {
      id: 2,
      title: 'Streamlined Hiring Process for Quality Drone Services',
      description:
        'Save time and effort with our intuitive search and hire system. Browse through profiles of vetted drone pilots, compare quotes, and read reviews to ensure you find the best match for your project. Our platform takes the hassle out of finding reliable drone operators',
      image: 'images/whoAreYou/image5.png',
    },
    {
      id: 3,
      title: 'Achieve Your Project Goals with Professional Drone Solutions',
      description:
        'Whether you need aerial photography, land surveying, or structural inspections, our platform provides access to a network of skilled drone pilots. Specify your requirements, receive multiple quotes, and choose the best offer. Ensure your project is completed to the highest standard with expert drone services',
      image: 'images/whoAreYou/image6.png',
    },
  ];

  const pilotProcessInfo = [
    {
      id: 1,
      title: 'Select a job',
      description:
        'Explore job listings, select ones that match your expertise, and apply effortlessly',
    },
    {
      id: 2,
      title: 'Propose a quote',
      description:
        'Present your skills and pricing clearly to attract clients with compelling proposals',
    },
    {
      id: 3,
      title: 'Get an offer',
      description:
        'Engage with clients directly, negotiate terms, and accept offers to start working',
    },
  ];

  const clientProcessInfo = [
    {
      id: 1,
      title: 'Place a job',
      description:
        'Describe your project needs and specifications to attract qualified drone pilots',
    },
    {
      id: 2,
      title: 'Receive offer',
      description:
        'Sit back as pilots submit proposals tailored to your requirements',
    },
    {
      id: 3,
      title: 'Choose a pilot',
      description: 'Review offers and select the best fit for your project',
    },
  ];

  const getProcessInfoCards = () => {
    const processInfo = isDroneOptionSelected
      ? pilotProcessInfo
      : clientProcessInfo;
    return processInfo.map((item) => (
      <div className="col-sm process_info_card" key={item.id}>
        <div className="process_info_card_content">
          <div className="process_info_card_header">
            <div className="process_info_card_id">
              <div className="process_info_card_id_content">{item.id}</div>
            </div>
            <div className="process_info_card_title">{item.title}</div>
          </div>
          <div className="process_info_card_description">
            {item.description}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="home_role">
        <div className="who_are_you">Who are you?</div>
        <div className="role_buttons row">
          <div
            className={`col-sm role_button drone_pilot_btn ${isDroneOptionSelected ? 'active-role-button' : ''}`}
            onClick={() => setIsDroneOptionSelected(true)}
          >
            <div className="role_btn_container">
              <div className="role_btn_content">
                <div className="role_btn_icon">
                  <Drone />
                </div>
                <div className="role_btn_text">Drone Pilot</div>
              </div>
            </div>
          </div>
          <div
            className={`col-sm role_button client_btn ${!isDroneOptionSelected ? 'active-role-button' : ''}`}
            onClick={() => setIsDroneOptionSelected(false)}
          >
            <div className="role_btn_container">
              <div className="role_btn_content">
                <div className="role_btn_icon">
                  <Person />
                </div>
                <div className="role_btn_text">Client</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="role_info_container">
        <div className="role_info_content">
          <div className="role_info_title_container">
            <div className="role_info_title_text">
              Next level Drone Platform
            </div>
          </div>
          <div className="position-relative justify-content-center role-info-main-info">
            <div
              className={`role-info-main-info-cards ${isDroneOptionSelected ? 'showing-role' : 'not-showing-role'}`}
            >
              {droneRoleInfo.map((item) => (
                <div
                  className="row role_info_section justify-content-center"
                  key={item.id}
                >
                  {item.id % 2 === 0 && (
                    <img
                      src={item.image}
                      alt={item.title}
                      className="whoAreYou_img"
                    />
                  )}
                  <div className="col-sm role_info_text">
                    <span className="role_info_title w-100">{item.title}</span>
                    <div className="role_info_description">
                      {item.description}
                    </div>
                  </div>
                  {item.id % 2 === 1 && (
                    <img
                      src={item.image}
                      alt={item.title}
                      className="whoAreYou_img"
                    />
                  )}
                </div>
              ))}
            </div>
            <div
              className={`role-info-main-info-cards ${!isDroneOptionSelected ? 'showing-role' : 'not-showing-role'}`}
            >
              {clientRoleInfo.map((item) => (
                <div
                  className={`row role_info_section justify-content-center ${!isDroneOptionSelected ? 'showing-role' : 'not-showing-role'}`}
                  key={item.id}
                >
                  {item.id % 2 === 0 && (
                    <img
                      src={item.image}
                      alt={item.title}
                      className="whoAreYou_img"
                    />
                  )}
                  <div className="col-sm role_info_text">
                    <span className="role_info_title w-100">{item.title}</span>
                    <div className="role_info_description">
                      {item.description}
                    </div>
                  </div>
                  {item.id % 2 === 1 && (
                    <img
                      src={item.image}
                      alt={item.title}
                      className="whoAreYou_img"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="process_info_container">
        <div className="process_info_content">
          <div className="process_info_header">
            <div className="process_info_text">The Process</div>
          </div>
          <div className="row justify-content-center process_info_cards">
            {getProcessInfoCards()}
          </div>
        </div>
      </div>
      <Link to="/app">
        <div className="find_client_btn">
          <div className="find_client_btn_container">
            <div className="find_client_btn_content">
              <div className="find_client_btn_first">Ready to Get Started?</div>
              <div className="find_client_btn_second">
                <div className="find_client_btn_second_text">
                  {isDroneOptionSelected ? 'Find a Client' : 'Find a Pilot'}
                </div>
                <div className="find_client_btn_arrow">
                  <Arrow />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
