import * as React from 'react';
import { CaptureHubLogo, DroneLogo } from '../../../resources/icons';
import { HomeHeaderButtons } from '../HomeHeaderButtons';
import { ProfileButton } from '../../ProfileButton';
import { useAuth } from '../../../contexts/authContext';

export const HomeHeader: React.FC = () => {
  const auth = useAuth();

  return (
    <div className="home_header">
      <div className="home-header-logo d-flex h-100 my-auto">
        <DroneLogo className="drone-logo-svg" />
        <CaptureHubLogo className="text-logo-svg" />
      </div>
      {auth?.token ? <ProfileButton /> : <HomeHeaderButtons />}
    </div>
  );
};
