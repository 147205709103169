import * as React from 'react';
import { RefObject, useState } from 'react';
import { EyeIcon, HiddenEyeIcon, ShortArrow } from '../../../resources/icons';

interface AuthInputProps {
  iconComponent?: React.ElementType;
  placeholder: string;
  textType?: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  ref?: RefObject<any>;
  hasArrow?: boolean;
  activeArrow?: boolean;
  error?: string;
  rightElement?: React.JSX.Element;
  currentValue?: string;
}

export const AuthInput: React.FC<AuthInputProps> = ({
  iconComponent: Component,
  placeholder,
  textType,
  onChange,
  onClick,
  onFocus,
  onBlur,
  ref,
  hasArrow,
  activeArrow,
  error,
  rightElement,
  currentValue,
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <div
      className="auth_input_container"
      onClick={onClick ? () => onClick() : () => null}
    >
      <div className={`auth_input ${error ? 'error_border' : ''}`}>
        {Component ? (
          <div className="auth_input_field_icon">
            <Component />
          </div>
        ) : (
          ''
        )}
        <div className="auth_input_field_container">
          <input
            className="auth_input_field"
            placeholder={placeholder}
            type={hidePassword ? textType : 'text'}
            onChange={onChange ? (e) => onChange(e.target.value) : () => null}
            onBlur={onBlur ? () => onBlur() : () => null}
            onFocus={onFocus ? () => onFocus() : () => null}
            ref={ref}
            value={currentValue}
          />
        </div>
        {hasArrow ? (
          <div
            className={`auth_form_select_field_arrow d-flex flex-column justify-content-center
              ${activeArrow ? 'auth_form_select_field_arrow_active' : ''}
            `}
          >
            <ShortArrow />
          </div>
        ) : (
          ''
        )}
        {rightElement || ''}
        {textType === 'password' ? (
          <div
            className="auth-input-eye-icon d-flex flex-column justify-content-center"
            onClick={() => setHidePassword(!hidePassword)}
          >
            {hidePassword ? <HiddenEyeIcon /> : <EyeIcon />}
          </div>
        ) : (
          ''
        )}
      </div>
      {error ? <div className="invalid-feedback d-block">{error}</div> : ''}
    </div>
  );
};
