import * as React from 'react';
import { useState } from 'react';
import { AxiosError } from 'axios';
import Select from 'react-select';
import { AuthInput } from '../../AuthInput';
import {
  BadgeIcon,
  Bag,
  CheckIcon,
  ClientIcon,
  Email,
  ErrorIcon,
  JoystickIcon,
  Password,
  SymbolA,
} from '../../../../resources/icons';
import { MainButton } from '../../MainButton';
import postRegister, { RegistrationData } from '../../../../api/register';
import { fieldSetErrors, getApiError } from '../../../../api/handleError';
import postValidateUserCreate from '../../../../api/validateUserCreate';
import postLogin from '../../../../api/login';
import getMe from '../../../../api/me';
import { useAuth } from '../../../../contexts/authContext';

interface SignupFormProps {
  onClientSubmit: () => void;
  onPilotSubmit: () => void;
  onClickBack: () => void;
  setRegisterData: (data: RegistrationData) => void;
}

export const SignupForm: React.FC<SignupFormProps> = ({
  onClientSubmit,
  onPilotSubmit,
  onClickBack,
  setRegisterData,
}) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [emailError, setEmailError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');

  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const setToken = auth?.setToken;
  const setUserId = auth?.setUserId;
  const setUserName = auth?.setUserName;

  const selectOptions = [
    {
      label: (
        <div>
          <JoystickIcon />
          <span>Pilot</span>
        </div>
      ),
      value: 'pilot',
    },
    {
      label: (
        <div>
          <ClientIcon />
          <span>Client</span>
        </div>
      ),
      value: 'client',
    },
  ];

  const onClickMainButton = async () => {
    setEmailError('');
    setRoleError('');
    setFirstNameError('');
    setLastNameError('');
    setBusinessNameError('');
    setPasswordError('');
    setRepeatPasswordError('');
    setLoading(true);

    if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords are not same!');
      setLoading(false);
      return;
    }

    if (role === 'client') {
      try {
        await postRegister({
          email,
          role,
          first_name: firstName,
          last_name: lastName,
          business_name: !businessName ? null : businessName,
          password,
          is_certified: false,
          is_insured: false,
          owned_drones: [],
          industries: [],
          services: [],
          video_links: [],
        });

        try {
          const response = await postLogin({
            email,
            password,
          });

          if (setToken) {
            await setToken(response.data.access);
          }
          localStorage.setItem('accessToken', response.data.access);
          localStorage.setItem('refreshToken', response.data.refresh);

          try {
            const meResponse = await getMe();
            if (setUserId) {
              await setUserId(meResponse.data.id);
            }
            if (setUserName) {
              await setUserName(
                `${meResponse.data.first_name} ${meResponse.data.last_name}`,
              );
            }
          } catch (meError) {
            setEmailError(' ');
            setFirstNameError(' ');
            setLastNameError(' ');
            setBusinessNameError(' ');
            setPasswordError(' ');
            setRepeatPasswordError(getApiError(meError));
          }
        } catch (loginError) {
          if (loginError instanceof AxiosError) {
            setEmailError(' ');
            setFirstNameError(' ');
            setLastNameError(' ');
            setBusinessNameError(' ');
            setPasswordError(' ');
            setRepeatPasswordError(getApiError(loginError));
          }
        }
        onClientSubmit();
      } catch (registerError) {
        const hasFieldErrors = fieldSetErrors(registerError, {
          role: setRoleError,
          email: setEmailError,
          first_name: setFirstNameError,
          last_name: setLastNameError,
          business_name: setBusinessNameError,
          password: setPasswordError,
        });
        if (!hasFieldErrors) {
          setEmailError(' ');
          setFirstNameError(' ');
          setLastNameError(' ');
          setBusinessNameError(' ');
          setPasswordError(' ');
          setRepeatPasswordError(getApiError(registerError));
        }
      } finally {
        setLoading(false);
      }
      return;
    }

    try {
      await postValidateUserCreate({
        email,
        role,
        first_name: firstName,
        last_name: lastName,
        business_name: !businessName ? null : businessName,
        password,
        is_certified: false,
        is_insured: false,
        owned_drones: [],
        industries: [],
        services: [],
        video_links: [],
      });
      setRegisterData({
        email,
        role,
        first_name: firstName,
        last_name: lastName,
        business_name: !businessName ? null : businessName,
        password,
        is_certified: false,
        is_insured: false,
        owned_drones: [],
        industries: [],
        services: [],
        video_links: [],
      });
      onPilotSubmit();
    } catch (registerError) {
      const hasRegisterFieldErrors = fieldSetErrors(registerError, {
        email: setEmailError,
        first_name: setFirstNameError,
        last_name: setLastNameError,
        business_name: setBusinessNameError,
        password: setPasswordError,
      });
      if (!hasRegisterFieldErrors) {
        setEmailError(' ');
        setFirstNameError(' ');
        setLastNameError(' ');
        setBusinessNameError(' ');
        setPasswordError(' ');
        setRepeatPasswordError(getApiError(registerError));
      }
    } finally {
      if (!role) {
        setRoleError('Please select account type');
      }
      if (!repeatPassword) {
        setRepeatPasswordError('This field may not be blank');
      }
      setLoading(false);
    }
  };

  return (
    <div className="auth_form_container">
      <div className="auth_form_content">
        <div className="auth_form_header">Sign Up</div>
        <div className="auth_form_body">
          <div className="auth_inputs">
            <div className="h-56">
              <Select
                name="colors"
                options={selectOptions}
                className={`${roleError ? 'error_border' : ''} br-24 mb-8 z-3`}
                classNamePrefix="select"
                onChange={(selectedOptions: any) => {
                  setRole(selectedOptions.value);
                  setRoleError('');
                }}
                placeholder={
                  <div className="d-flex">
                    <BadgeIcon style={{ marginLeft: '6px' }} />
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{
                        marginLeft: '12px',
                      }}
                    >
                      Account type*
                    </div>
                  </div>
                }
              />
              {roleError ? (
                <div className="invalid-feedback d-block">{roleError}</div>
              ) : (
                ''
              )}
            </div>
            <AuthInput
              iconComponent={Email}
              onChange={setEmail}
              error={emailError}
              placeholder="Your email*"
            />
            <AuthInput
              iconComponent={SymbolA}
              onChange={setFirstName}
              error={firstNameError}
              placeholder="First name*"
            />
            <AuthInput
              iconComponent={SymbolA}
              onChange={setLastName}
              error={lastNameError}
              placeholder="Last name*"
            />
            <AuthInput
              iconComponent={Bag}
              onChange={setBusinessName}
              error={businessNameError}
              placeholder="Business name"
            />
            <AuthInput
              iconComponent={Password}
              onChange={setPassword}
              error={passwordError}
              placeholder="Password*"
              textType="password"
            />
            <AuthInput
              iconComponent={Password}
              onChange={setRepeatPassword}
              error={repeatPasswordError}
              placeholder="Repeat password*"
              textType="password"
            />
            <div className="d-flex flex-column password-realtime-validation">
              <div>
                {password.length >= 8 ? <CheckIcon /> : <ErrorIcon />}
                <span className="ml-8">Minimum 8 characters</span>
              </div>
              <div>
                {/\d/.test(password) ? <CheckIcon /> : <ErrorIcon />}
                <span className="ml-8">At least one number</span>
              </div>
              <div>
                {/[A-Z]/.test(password) ? <CheckIcon /> : <ErrorIcon />}
                <span className="ml-8">At least one uppercase letter</span>
              </div>
              <div>
                {/[!@#$%^&*(),.?":{}|<>]/.test(password) ? (
                  <CheckIcon />
                ) : (
                  <ErrorIcon />
                )}
                <span className="ml-8">At least one symbol</span>
              </div>
            </div>
          </div>
          <MainButton
            text="Sign Up"
            buttonColor={
              role && firstName && lastName && password && repeatPassword
                ? '#C3FF01'
                : 'rgba(195, 255, 1, 0.50)'
            }
            textColor=""
            onClick={onClickMainButton}
            cursor={
              role && firstName && lastName && password && repeatPassword
                ? 'pointer'
                : 'not-allowed'
            }
            loading={loading}
          />
          <div className="login_redirect">
            <span className="login_redirect_content">
              Already have an account? <span onClick={onClickBack}>Login</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
