import React from 'react';

interface LoaderProps {
  spinnerWidth: number;
  borderWidth: number;
  primaryColor?: string;
  secondColor?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  spinnerWidth,
  borderWidth,
  primaryColor = '',
  secondColor = '',
}: LoaderProps) => (
  <div
    className="loader mx-auto"
    style={{
      width: `${spinnerWidth}px`,
      height: `${spinnerWidth}px`,
      borderWidth: `${borderWidth}px`,
      borderColor: `${primaryColor}px`,
      borderTopColor: `${secondColor}px`,
    }}
  />
);
