import { axiosInstance } from './axios';

export interface LoginData {
  email: string;
  password: string;
}

const postLogin = async (data: LoginData) =>
  axiosInstance.post('/auth/jwt/create', data);

export default postLogin;
