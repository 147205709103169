import React from 'react';

interface ButtonData {
  text: string;
  iconComponent: React.ElementType;
}

interface ToggleButtonProps {
  leftBtnData: ButtonData;
  rightBtnData: ButtonData;
  toggle: boolean;
  toggleUnset?: boolean;
  handleToggleChange: (toggleValue: boolean) => void;
  buttonStyle?: 'Auth' | 'Dashboard' | 'Profile';
}

const getToggleContainerClass = (buttonStyle: string) => {
  switch (buttonStyle) {
    case 'Dashboard':
      return 'toggle-container toggle-container-dashboard m-0 h-100 gap-0';
    case 'Profile':
      return 'toggle-container toggle-container-profile m-0 h-100 gap-0';
    default:
      return 'toggle-container';
  }
};

const getButtonContentClass = (buttonStyle: string, isActive: boolean) => {
  const baseClass = 'auth_switch_button_content';
  const dashboardClass = 'auth_switch_button_content_dashboard';
  const dashboardActiveClass = 'auth_switch_button_content_dashboard_active';
  const profileClass = 'auth_switch_button_content_profile';
  const profileInactiveClass = 'auth_switch_button_content_profile_inactive';
  if (buttonStyle === 'Dashboard') {
    return `${baseClass} ${dashboardClass} ${isActive ? dashboardActiveClass : ''}`;
  }
  if (buttonStyle === 'Profile') {
    return `${baseClass} ${profileClass} ${isActive ? '' : profileInactiveClass}`;
  }
  return baseClass;
};

const getButtonIconClass = (buttonStyle: string, isActive: boolean) => {
  const baseClass = 'auth_switch_button_icon';
  const dashboardClass = 'auth_switch_button_icon_dashboard';
  const activeClass = 'auth_switch_button_icon_active';
  if (buttonStyle === 'Dashboard') {
    return `${baseClass} ${dashboardClass} ${isActive ? activeClass : ''}`;
  }
  return `${baseClass} ${isActive ? activeClass : ''}`;
};

const getToggleButtonClass = (buttonStyle: string, isActive: boolean) => {
  const baseClass = 'toggle-btn';
  const dashboardClass = 'toggle-btn-dashboard';
  const disableClass = 'toggle-btn-disable';
  const dashboardDisableClass = 'toggle-btn-dashboard-disable';
  if (buttonStyle === 'Dashboard') {
    return `${baseClass} ${dashboardClass} ${isActive ? '' : dashboardDisableClass}`;
  }
  return `${baseClass} ${isActive ? '' : disableClass}`;
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  leftBtnData,
  rightBtnData,
  toggle,
  handleToggleChange,
  toggleUnset,
  buttonStyle = 'Auth',
}) => {
  const handleLeftClick = () => {
    handleToggleChange(false);
  };

  const handleRightClick = () => {
    handleToggleChange(true);
  };

  return (
    <div
      className={getToggleContainerClass(buttonStyle)}
      style={buttonStyle === 'Dashboard' ? { background: '#FFF' } : {}}
    >
      <div
        className="auth_switch_button"
        style={buttonStyle === 'Dashboard' ? { width: '50%' } : {}}
        onClick={handleLeftClick}
      >
        <div className={getButtonContentClass(buttonStyle, !toggle)}>
          <div
            className={getButtonIconClass(buttonStyle, !toggleUnset && !toggle)}
          >
            <leftBtnData.iconComponent />
          </div>
          <div className="auth_switch_button_text">{leftBtnData.text}</div>
        </div>
      </div>
      <div className="auth_switch_button" onClick={handleRightClick}>
        <div className={getButtonContentClass(buttonStyle, toggle)}>
          <div
            className={getButtonIconClass(buttonStyle, !toggleUnset && toggle)}
          >
            <rightBtnData.iconComponent />
          </div>
          <div className="auth_switch_button_text">{rightBtnData.text}</div>
        </div>
      </div>
      {!toggleUnset && (
        <div className={getToggleButtonClass(buttonStyle, toggle)} />
      )}
    </div>
  );
};
