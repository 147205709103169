import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Login } from './Login';
import { CaptureHub, Drone2, Lock, LoginIcon } from '../../resources/icons';
import { Signup } from './Signup';
import { ToggleButton } from '../ToggleButton';
import { ForgotPassword } from './ForgotPassword';
import { ActivateAccountForm } from './ActivateAccount';
import { useAuth } from '../../contexts/authContext';

export interface AuthPageProps {
  initialForm?:
    | 'login'
    | 'signUp'
    | 'resetPassword'
    | 'requestResetPassword'
    | 'activateAccount'
    | 'passwordChanged';
}

export const AuthPage: React.FC<AuthPageProps> = ({
  initialForm = 'login',
}) => {
  type Variant =
    | 'login'
    | 'signUp'
    | 'resetPassword'
    | 'requestResetPassword'
    | 'activateAccount'
    | 'passwordChanged';

  const [currentForm, setCurrentForm] = useState<Variant>(initialForm);

  const [toggle, setToggle] = useState(false);

  const auth = useAuth();

  const leftToggleButton = {
    text: 'Login',
    iconComponent: LoginIcon,
  };
  const rightToggleButton = {
    text: 'Sign Up',
    iconComponent: Lock,
  };

  return (
    <div className="auth_page__wrapper position-relative">
      <svg
        viewBox="100% 100% 100% 100%"
        xmlns="http://www.w3.org/2000/svg"
        className="auth-page-background_noise"
      >
        <filter id="noiseFilter">
          <feTurbulence
            type="fractalNoise"
            baseFrequency="1"
            numOctaves="6"
            stitchTiles="stitch"
          />
        </filter>
        <rect width="100%" height="100%" filter="url(#noiseFilter)" />
      </svg>
      <div className="auth_page__backgroundd">
        <div className="auth_page__gradient">
          <div className="auth_page__gradient_background">
            <div className="auth_page__content">
              <div className="auth_page_header mx-auto">
                <Link to="/" className="d-flex">
                  <Drone2 />
                  <CaptureHub />
                </Link>
              </div>
              {!auth?.token && (
                <ToggleButton
                  leftBtnData={leftToggleButton}
                  rightBtnData={rightToggleButton}
                  toggle={toggle}
                  toggleUnset={!['login', 'signUp'].includes(currentForm)}
                  handleToggleChange={(toggleValue: boolean) => {
                    setToggle(toggleValue);
                    setCurrentForm(toggleValue ? 'signUp' : 'login');
                  }}
                />
              )}
              <div className="auth_page_forms">
                <div
                  className="w-100 h-100"
                  style={
                    currentForm === 'resetPassword' ? {} : { display: 'none' }
                  }
                >
                  <ForgotPassword
                    onClickBack={() => setCurrentForm('login')}
                    initialForm="resetPassword"
                  />
                </div>

                <div
                  className="w-100 h-100"
                  style={
                    currentForm === 'activateAccount' ? {} : { display: 'none' }
                  }
                >
                  {currentForm === 'activateAccount' ? (
                    <ActivateAccountForm />
                  ) : (
                    ''
                  )}
                </div>

                <div
                  className="w-100 h-100"
                  style={
                    currentForm === 'requestResetPassword'
                      ? {}
                      : { display: 'none' }
                  }
                >
                  <ForgotPassword
                    onClickBack={() => setCurrentForm('login')}
                    initialForm="requestResetPassword"
                  />
                </div>

                <div
                  className="w-100 h-100"
                  style={currentForm === 'login' ? {} : { display: 'none' }}
                >
                  <Login
                    onClickForgotPassword={() =>
                      setCurrentForm('requestResetPassword')
                    }
                    onClickSignUp={() => {
                      setToggle(true);
                      setCurrentForm('signUp');
                    }}
                  />
                </div>

                <div
                  className="w-100 h-100"
                  style={toggle ? {} : { display: 'none' }}
                >
                  <Signup
                    onClickBack={() => {
                      setToggle(false);
                      setCurrentForm('login');
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
