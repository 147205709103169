import * as React from 'react';
import { CSSProperties, useState } from 'react';
import { ShortArrow } from '../../../resources/icons';

interface Label {
  text: string;
  value: string;
  icon?: React.ElementType;
}

interface AuthSelectProps {
  iconComponent?: React.ElementType;
  placeholder: string;
  selectOptions: Label[];
  onChange: (value: string) => void;
  error?: string;
  style?: CSSProperties | undefined;
}

export const AuthSelectField: React.FC<AuthSelectProps> = ({
  iconComponent: Component,
  placeholder,
  selectOptions,
  onChange,
  error,
  style,
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <div style={style}>
      <div
        className={`dropdown auth_form_select_field ${error ? 'error_border' : ''}`}
      >
        <button
          className="auth_form_select_field_button btn w-100 h-100"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            border: 'none',
            padding: '0 16px',
          }}
        >
          <div className="auth_form_select_field_button_content">
            {Component ? (
              <div className="auth_form_select_field_icon d-flex flex-column justify-content-center">
                <Component />
              </div>
            ) : (
              ''
            )}
            <div
              className="auth_form_select_field_text"
              style={selectedOption ? { color: '#FFF' } : { color: '#77828C' }}
            >
              {selectedOption || placeholder}
            </div>
            <div
              className={`auth_form_select_field_arrow d-flex flex-column justify-content-center
              ${selectedOption ? 'auth_form_select_field_arrow_active' : ''}
            `}
            >
              <ShortArrow />
            </div>
          </div>
        </button>
        <ul
          className="dropdown-menu w-100 auth_form_select_field_options"
          style={{ backgroundColor: 'rgba(2, 2, 6, 1)' }}
        >
          {selectOptions.map((item) => (
            <li>
              <div
                className="dropdown-item auth_form_select_field_option d-flex flex-column justify-content-center"
                onClick={() => {
                  onChange(item.value);
                  setSelectedOption(item.text);
                }}
              >
                <div className="d-flex">
                  {item.icon ? <item.icon /> : ''}
                  <span>{item.text}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {error ? <div className="invalid-feedback d-block">{error}</div> : ''}
    </div>
  );
};
