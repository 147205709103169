import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import postActivateAccount from '../../../api/activateAccount';
import { fieldSetErrors } from '../../../api/handleError';

export const ActivateAccountForm: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      setErrorMessage('Token is not valid');
    } else {
      postActivateAccount({
        token,
      })
        .then((response) => {
          setErrorMessage(response.data.detail);
        })
        .catch((error) => {
          fieldSetErrors(error, {
            detail: setErrorMessage,
          });
        });
    }
  }, [searchParams]);

  return (
    <div className="forgot_password_form">
      <div className="forgot_password_content">
        <div className="forgot_password_header">Activate account</div>
        <div className="activate_account_form_body">
          <div className="activate_account_form_body_content">
            {errorMessage}
          </div>
        </div>
      </div>
    </div>
  );
};
