import { axiosInstance } from './axios';

export interface resendActivationCodeData {
  email: string;
}

const resendActivationCode = async (data: resendActivationCodeData) =>
  axiosInstance.post('/users/users/resend-activation-code', data);

export default resendActivationCode;
