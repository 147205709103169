import * as React from 'react';
import { Arrow, ClientIcon, JoystickIcon } from '../../../resources/icons';
import { AuthSelectField } from '../../AuthPage/AuthSelectField';

export const WorkHeader = ({ text }) => {
  const selectOptions = [
    {
      text: 'Coming soon...',
      value: 'null',
    },
  ];

  return (
    <div className="work-header h-72 d-flex flex-column justify-content-center">
      <div className="work-header-content d-flex justify-content-between gap-8">
        <div className="scroll d-flex gap-8">
          <div className="scroll-top">
            <Arrow />
          </div>
          <div className="scroll-down">
            <Arrow />
          </div>
        </div>
        <div className="title d-flex flex-column justify-content-center">
          {text}
        </div>
        <AuthSelectField
          placeholder="Sort by"
          selectOptions={selectOptions}
          onChange={() => null}
        />
      </div>
    </div>
  );
};
