import { axiosInstance } from './axios';

export interface LogoutData {
  refresh: string;
}

const postLogout = async (data: LogoutData) =>
  axiosInstance.post('/auth/jwt/logout', data);

export default postLogout;
