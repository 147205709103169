import { axiosInstance } from './axios';

export interface VideoLinkData {
  user: number;
  link: string;
}

const postVideoLink = async (data: VideoLinkData) =>
  axiosInstance.post('/users/video-links', data);

export default postVideoLink;
