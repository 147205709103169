import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from './App/App';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import './styles/index.scss';
import './styles/index.css';

import 'mapbox-gl/dist/mapbox-gl.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<App />);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    // returns installed service workers
    if (registrations.length) {
      Object.keys(registrations).forEach((registration) => {
        registrations[registration].unregister();
      });
    }
  });
}
