import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Arrow, Email, Password } from '../../../resources/icons';
import { MainButton } from '../MainButton';
import { AuthInput } from '../AuthInput';
import postLogin from '../../../api/login';
import { useAuth } from '../../../contexts/authContext';
import { fieldSetErrors, getApiError } from '../../../api/handleError';
import getMe from '../../../api/me';
import resendActivationCode from '../../../api/resendActivationCode';

interface LoginProps {
  onClickForgotPassword: () => void;
  onClickSignUp: () => void;
}

export const Login: React.FC<LoginProps> = ({
  onClickForgotPassword,
  onClickSignUp,
}) => {
  type Variant = null | 'requestActivateLink' | 'activateLinkSent';

  const [currentForm, setCurrentForm] = useState<Variant>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [requestActivationError, setRequestActivationError] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const auth = useAuth();
  const setToken = auth?.setToken;
  const setUserId = auth?.setUserId;
  const setUserName = auth?.setUserName;

  const onClickMainButton = async () => {
    setEmailError('');
    setPasswordError('');
    setLoading(true);
    try {
      const response = await postLogin({
        email,
        password,
      });
      if (setToken) {
        setToken(response.data.access);
      }
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      try {
        const meResponse = await getMe();
        if (setUserId) {
          setUserId(meResponse.data.id);
        }
        if (setUserName) {
          setUserName(
            `${meResponse.data.first_name} ${meResponse.data.last_name}`,
          );
        }
        navigate('/app');
      } catch (meError) {
        setEmailError(' ');
        setPasswordError(getApiError(meError));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const hasFieldErrors = fieldSetErrors(error, {
          email: setEmailError,
          password: setPasswordError,
        });
        if (!hasFieldErrors) {
          setEmailError(' ');
          setPasswordError(getApiError(error));
        }
      }
    }
    setLoading(false);
  };

  const onClickActivateAccount = () => {
    setRequestActivationError('');
    try {
      resendActivationCode({
        email,
      });
      setCurrentForm('activateLinkSent');
    } catch (error) {
      if (error instanceof AxiosError) {
        setRequestActivationError(getApiError(error));
      }
    }
  };

  return (
    <div className="auth_form_container">
      {!currentForm ? (
        <div className="auth_form_content">
          <div className="auth_form_header">Login</div>
          <div className="auth_form_body">
            <div className="auth_inputs">
              <AuthInput
                iconComponent={Email}
                onChange={setEmail}
                placeholder="Email"
                error={emailError}
              />
              <AuthInput
                iconComponent={Password}
                onChange={setPassword}
                placeholder="Password"
                textType="password"
                error={passwordError}
              />
              {passwordError ===
              'Account is not active. Please activate your account.' ? (
                <div className="d-flex justify-content-center">
                  <span
                    className="forgot_password_redirect forgot_password_redirect_content m-0"
                    onClick={() => setCurrentForm('requestActivateLink')}
                  >
                    Resend activation link
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="forgot_password_redirect">
              <span
                className="forgot_password_redirect_content"
                onClick={onClickForgotPassword}
              >
                Forgot password?
              </span>
            </div>
            <MainButton
              text="Login"
              buttonColor={
                email && password ? '#C3FF01' : 'rgba(195, 255, 1, 0.50)'
              }
              cursor={email && password ? 'pointer' : 'not-allowed'}
              onClick={onClickMainButton}
              loading={loading}
            />
          </div>
          <div className="login_redirect">
            <span className="login_redirect_content">
              Dont have an account? <span onClick={onClickSignUp}>Sign up</span>
            </span>
          </div>
        </div>
      ) : (
        ''
      )}
      {currentForm === 'requestActivateLink' ? (
        <div className="forgot_password_content">
          <div className="forgot_password_header">
            <div className="forgot_password_header_icon_container">
              <Arrow
                className="forgot_password_header_icon"
                onClick={() => setCurrentForm(null)}
              />
            </div>
            Activate account
          </div>
          <div className="forgot_password_input">
            <AuthInput
              currentValue={email}
              iconComponent={Email}
              placeholder="Your email"
              error={requestActivationError}
            />
          </div>
          <div className="forgot_password_button">
            <MainButton
              text="Send activation link"
              onClick={onClickActivateAccount}
              buttonColor={
                email && password ? '#C3FF01' : 'rgba(195, 255, 1, 0.50)'
              }
              cursor={email && password ? 'pointer' : 'not-allowed'}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {currentForm === 'activateLinkSent' ? (
        <div className="forgot_password_content">
          <div className="forgot_password_header">Activation link</div>
          <div className="forgot_password_success_form_body">
            <div className="forgot_password_success_form_body_content">
              Activation link sent to <span>{email}</span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
