import { axiosInstance } from './axios';

export interface RegistrationData {
  email: string;
  role: string;
  first_name: string;
  last_name: string;
  business_name?: string | null;
  password: string;
  is_certified: boolean;
  is_insured: boolean;
  owned_drones: number[];
  industries: number[];
  other_owned_drones?: string;
  other_industries?: string;
  services: number[];
  video_links: string[];
}

const postRegister = async (data: RegistrationData) =>
  axiosInstance.post('/users/users', data);

export default postRegister;
