import * as React from 'react';
import { WorkList } from '../../WorkList/WorkList';

export const Invites = () => {
  const worksList = [
    {
      title: 'Coming soon...',
      category: 'Sorry, we are working on it',
      created_at: '2024-04-04T18:04:25Z',
    },
  ];

  return <WorkList worksList={worksList} workType="invite" />;
};
