import Axios from 'axios';
import postRefreshToken from './refreshToken';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

axiosInstance.interceptors.response.use(
  (config) => config,

  async (error) => {
    const originalRequest = { ...error.config };
    if (
      error.response.status === 401 &&
      error.config &&
      error.config.url !== '/auth/jwt/refresh'
    ) {
      try {
        const refresh = localStorage.getItem('refreshToken');
        if (!refresh) {
          throw error;
        }
        const response = await postRefreshToken({
          refresh,
        });
        localStorage.setItem('accessToken', response.data.access);
        return axiosInstance.request(originalRequest);
      } catch (e) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        throw error;
      }
    }
    throw error;
  },
);
