import * as React from 'react';
import { useState } from 'react';
import { AuthInput } from '../../AuthInput';
import { Arrow, Email } from '../../../../resources/icons';
import { MainButton } from '../../MainButton';
import requestResetPassword from '../../../../api/requestResetPassword';
import { fieldSetErrors, getApiError } from '../../../../api/handleError';

interface ForgotPasswordInputFormProps {
  onSubmit: () => void;
  setEmail: (email: string) => void;
  onClickBack: () => void;
}

export const ForgotPasswordInputForm: React.FC<
  ForgotPasswordInputFormProps
> = ({ onSubmit, onClickBack, setEmail }) => {
  const [componentEmail, setComponentEmail] = useState('');
  const [componentEmailError, setComponentEmailError] = useState('');

  const setInputEmail = (email: string) => {
    setComponentEmail(email);
    setEmail(email);
  };

  const onClickMainButton = () => {
    requestResetPassword({
      email: componentEmail,
    })
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        const hasFieldErrors = fieldSetErrors(error, {
          detail: setComponentEmailError,
          email: setComponentEmailError,
        });
        if (!hasFieldErrors) {
          setComponentEmailError(getApiError(error));
        }
      });
  };

  return (
    <div className="forgot_password_content">
      <div className="forgot_password_header">
        <div className="forgot_password_header_icon_container">
          <Arrow
            onClick={onClickBack}
            className="forgot_password_header_icon"
          />
        </div>
        Reset password
      </div>
      <div className="forgot_password_input">
        <AuthInput
          iconComponent={Email}
          placeholder="Your email"
          onChange={setInputEmail}
          error={componentEmailError}
        />
      </div>
      <div className="forgot_password_button">
        <MainButton
          text="Send reset link"
          buttonColor={componentEmail ? '#C3FF01' : 'rgba(195, 255, 1, 0.50)'}
          cursor={componentEmail ? 'pointer' : 'not-allowed'}
          onClick={onClickMainButton}
        />
      </div>
    </div>
  );
};
