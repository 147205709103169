import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import useWindowDimensions from '../../../utils/windowDimensions';

export const CardCarousel = () => {
  const { windowWidth } = useWindowDimensions();

  const [slidesToShow, setSlidesToShow] = useState(1);
  const [sliderWidth, setSliderWidth] = useState(256);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
  };

  const Images = [
    {
      id: 1,
      src: 'images/carousel/image1.png',
      alt: 'Drone Mapping',
      title: 'Drone Mapping',
    },
    {
      id: 2,
      src: 'images/carousel/image2.png',
      alt: 'Drone 3D Modelling',
      title: 'Drone 3D Modelling',
    },
    {
      id: 3,
      src: 'images/carousel/image3.png',
      alt: 'Drone Roof Inspection',
      title: 'Drone Roof Inspection',
    },
    {
      id: 4,
      src: 'images/carousel/image4.png',
      alt: 'Construction Monitoring',
      title: 'Construction Monitoring',
    },
    {
      id: 5,
      src: 'images/carousel/image5.png',
      alt: 'Wide Range of Services',
      title: 'Wide Range of Services',
    },
    {
      id: 6,
      src: 'images/carousel/image6.png',
      alt: 'User-Friendly Interface',
      title: 'User-Friendly Interface',
    },
    {
      id: 7,
      src: 'images/carousel/image7.png',
      alt: 'Secure Payment',
      title: 'Secure Payment',
    },
    {
      id: 8,
      src: 'images/carousel/image8.png',
      alt: 'Construction Scan',
      title: 'Construction Scan',
    },
  ];

  useEffect(() => {
    const carouselWidths = [
      {
        carouselWidth: 1096,
        cardsCount: 4,
      },
      {
        carouselWidth: 816,
        cardsCount: 3,
      },
      {
        carouselWidth: 536,
        cardsCount: 2,
      },
      {
        carouselWidth: 300,
        cardsCount: 1,
      },
    ];

    if (windowWidth < 430) {
      setSlidesToShow(1);
      setSliderWidth(200);
      return;
    }
    carouselWidths.some((item) => {
      if (item.carouselWidth < windowWidth - 24 * 2 - 40 * 2 - 20 * 2) {
        setSlidesToShow(item.cardsCount);
        setSliderWidth(item.carouselWidth);
        return true;
      }
      return false;
    });
  }, [windowWidth]);

  return (
    <div
      className="mx-auto"
      style={{ maxWidth: sliderWidth, marginTop: '130px' }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider {...settings}>
        {Images.map((item) => (
          <div key={item.id} className="carousel_card br-32">
            <img src={item.src} alt={item.alt} className="carousel_img" />
            <div className="carousel_card_title_container">
              <div className="carousel_card_title">
                <div className="carousel_card_title_text">{item.title}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
